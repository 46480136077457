import React, {useState} from 'react';
import {
    Grid,
    Typography,
    TextField,
    FormGroup,
    FormLabel,
    FormControlLabel,
    Checkbox,
    Button,
    InputAdornment, Tooltip, IconButton, useTheme
} from "@mui/material";
import {Form, Formik} from "formik";
import * as yup from "yup";
import SoloFormWrapper from "./SoloFormWrapper";
import Link from 'fit/components/System/Link';
import MaskedInput from "../Form/MaskedInput";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {getSpacing, regexPasswordCheck, DebugTitleObj, getStatusColor, getStatusIcon} from "fit/system/UtilityFunctions";
import {URL_LOGIN, AGREEMENT_STYLES, ICON_SX} from "fit/system/FITConstants";
import Modal from 'fit/components/Dialogs/Modal';
import SecureConnect from "fit/system/SecureConnect";
import CloseButton from "../InterfaceButtons/CloseButton";
import TermsOfService from "../LegalAgreements/TermsOfService";
import PrivacyPolicy from "../LegalAgreements/PrivacyPolicy";
import TabSection from "../TabSection";
import PaperMargin from "../PaperMargin";
import PasswordChecks from "./ResetPassword/PasswordChecks";
import PreferencesAccordion from "../PreferencesAccordion";


const initForm=()=>{
    return {
        email: '',
        phone: '',
        password: '',
        twoFactor: false,
        promoCode: '',
        agreed: false,
    }
}

const initModal=()=>{
    return {
        title: '',
        display: false,
        section: ''
    }
}

const initPasswordChecks =()=>{
    return regexPasswordCheck('','', false);
}

const RegisterAccount=()=>{
    const [form, setForm] = useState(initForm);
    const [passwordChecks, setPasswordChecks] = useState(initPasswordChecks);
    const [displayPassword, setDisplayPassword] = useState(false);
    const [modalData, setModalData] = useState(initModal);







    const createAccount=()=>{
        const sc = new SecureConnect('system.php', 'post');
        sc.setAction('createNewAccount');
        sc.setFormData(form);
        sc.connect().then(json=>{

        })
    }
    const handleField=(name, value)=>{
        let f = {...form};
        f[name] = value;
        setForm(f);
        if(name === 'password'){
            setPasswordChecks(regexPasswordCheck(value, '', false));
        }
    }
    const toggleCheckbox=(name, status)=>{
        let f = {...form};
        f[name] = !status;
        setForm(f);
    }

    const setModal=(title, section)=>{
        setModalData({display: true, title, section});
    }
    const closeModal=()=>{
        let m = {...modalData};
        m.display = false;
        setModalData(m);
    }


    const {email, phone, password, promoCode, agreed} = form;
    const {section} = modalData;
    const mgTop = {marginTop: getSpacing()}



    const t = useTheme();
    const primaryColor = t.palette.primary.main;
    const modalButton = {color: primaryColor, cursor: 'pointer'};
    const pc = passwordChecks;
    const passwordValid = pc.minChars && pc.capital && pc.lower && pc.number && pc.special;


    const passwordStatus = passwordValid ? 'success' : 'error';
    const iconColor =  getStatusColor(passwordStatus);
    const passIcon = getStatusIcon(passwordStatus, {color: iconColor, ...ICON_SX});
    const passSummaryText = passwordValid ? 'Password Secure' : 'Password Insecure';
    const passSummary = <span style={{color: iconColor}}>{passSummaryText}</span>;



    const validationSchema = yup.object().shape({
        passwordValid: yup.boolean(),
        email: yup.string()
            .required('Email required')
            .email('Email required')
            .min('5','Email required'),
        password: yup.string()
            .min('12', 'Minimum 12 characters required')
            /*
            .when('passwordValid', {
                is: false,
                then: yup.string().required('Password does not meet requirements')
            })*/
            .required('Password required'),
        promoCode: yup.string()
            .required('Key required')
            .min('5', 'Key required')
            .max('15', 'Key required'),
        phone: yup.string()
            .trim()
            .min(14, 'Phone number is incomplete')
    });


    const initValues = {...form, passwordValid};


    return (
        <SoloFormWrapper>
            <Formik
                initialValues={initValues}
                validationSchema={validationSchema}
                onSubmit={()=>createAccount()}
                enableReinitialize={true}
            >
                {({ errors, touched}) => (
                    <Form>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant={'h1'}>
                                    Create An Account
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label={'Beta Key'}
                                    value={promoCode}
                                    onChange={(e)=>handleField('promoCode', e.target.value)}
                                    helperText={errors.promoCode || ' '}
                                    error={errors.promoCode}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label={'Email'}
                                    type={'email'}
                                    value={email}
                                    onChange={(e)=>handleField('email', e.target.value)}
                                    helperText={errors.email || ' '}
                                    error={errors.email}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type={displayPassword ? 'text' : 'password'}
                                    name="password"
                                    label="Password"
                                    fullWidth
                                    onChange={(e)=>handleField('password', e.target.value)}
                                    value={password}
                                    error={(errors.password && touched.password)}
                                    helperText={errors.password || ' '}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <Tooltip title={'Toggle Password Visibility'} placement={'bottom'}>
                                                <IconButton
                                                    edge={'end'}
                                                    color={'primary'}
                                                    onClick={()=>setDisplayPassword(!displayPassword)}
                                                >
                                                    {displayPassword ? <VisibilityOff/>: <Visibility/>}
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    }}
                                />
                                <PreferencesAccordion
                                    summaryText={passSummary}
                                    icon={passIcon}
                                >
                                    <PasswordChecks
                                        passwordLength={password.length}
                                        capital={pc.capital}
                                        lower={pc.lower}
                                        match={pc.match}
                                        minChars={pc.minChars}
                                        number={pc.number}
                                        special={pc.special}
                                        displayMatch={false}
                                    />
                                </PreferencesAccordion>
                            </Grid>
                            {/*
                            <Grid item xs={12}>
                                <MaskedInput
                                    name="phone"
                                    pattern={"phone"}
                                    label="Phone"
                                    type="phone"
                                    value={phone}
                                    placeholder="(888) 643-2583"
                                    error={errors.phone}
                                    touched={touched.phone}
                                    onChange={(e)=>handleField('phone', e.target.value)}
                                    helperText={errors.phone || ' '}
                                    maxLength="14"
                                    fullWidth
                                />
                            </Grid>
                            */}

                            {/*
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox checked={form.twoFactor} onChange={()=>toggleCheckbox('twoFactor', form.twoFactor)}/>}
                                    label={
                                        <span>
                                            By checking this box, you agree to receive SMS messages from Buteo.
                                            You may reply Stop to opt-out at any time. Message and data rates apply.
                                            Message frequency will vary.
                                            <span> </span>
                                            <span style={modalButton} onClick={()=>setModal('SMS Terms of Service', 'smsTos')}>Buteo SMS Terms of Service</span>
                                        </span>
                                    }
                                />
                            </Grid>
                            */}
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox checked={form.agreed} onChange={()=>toggleCheckbox('agreed', form.agreed)}/>}
                                    label={
                                        <span>
                                            I have read and agree to Buteo's
                                    <span> </span>
                                    <span style={modalButton} onClick={()=>setModal('Terms of Service', 'tos')}>
                                                Terms of Service
                                            </span> and
                                    <span> </span>
                                    <span style={modalButton} onClick={()=>setModal('Privacy Policy', 'privacy')}>
                                                 Privacy Policy
                                             </span>.
                                        </span>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    disabled={!passwordValid || !form.agreed}
                                    type={'submit'}
                                    variant={'contained'}
                                    color={'primary'}
                                >Sign Up</Button>
                            </Grid>

                            <Grid item xs={12} sx={mgTop}>
                                <Link to={URL_LOGIN}>I already have an account</Link>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
            <Modal
                title={modalData.title}
                open={modalData.display}
                closeFunction={()=>closeModal()}
                modalActions={
                    <CloseButton
                        onClick={()=>closeModal()}
                    />
                }
            >
            {
                section === 'tos' &&
                <TabSection style={AGREEMENT_STYLES}>
                    <TermsOfService/>
                </TabSection>
            }

            {
                section === 'privacy' &&
                <TabSection style={AGREEMENT_STYLES}>
                    <PrivacyPolicy/>
                </TabSection>
            }

            {
                section === 'smsTos' &&
                <div>
                    SMS Terms of Service
                </div>
            }


            </Modal>

        </SoloFormWrapper>
    )
}

export default RegisterAccount;