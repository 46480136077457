import React, {useState} from 'react';

import PermissionsManager from "./PermissionsManager";
import {Grid, Typography, Button} from "@mui/material";
import Header from "../../Header";
import SecureConnect from "fit/system/SecureConnect";
import PaperMargin from "../../PaperMargin";
import User from 'fit/system/User';
import PreferencesAccordion from "../../PreferencesAccordion";
import {DebugTitleObj} from "fit/system/UtilityFunctions";
import {connect} from "react-redux";

const initButtonData =()=>{
    return {
        deptID: null,
        managerLevel: 0
    }
}

const PMTester=()=>{

    const [buttonData, setButtonData] = useState(initButtonData)

    const setPermissions=(deptID, managerLevel)=>{
        //SET permissions for both the editor and the viewer

        setButtonData({deptID: parseInt(deptID), managerLevel: parseInt(managerLevel)});


        const sc = new SecureConnect('___debugPermissionTester.php', 'post');
        sc.setAction('updateTest');
        sc.setFormData({deptID, managerLevel});
        sc.connect().then(json =>{

        });
    }

    const submitInvitation=()=>{

    }

    const submitPermissionChange=()=>{

    }

    const renderTestButtons=(columnDeptID)=>{
        let buttons = [];
        const editorRanks = [1000, 2000, 2001, 3000];
        const  {deptID, managerLevel} = buttonData;
        editorRanks.map(rank =>{
            const variant = deptID === parseInt(columnDeptID) ? 'contained' : 'outlined';
            const color = managerLevel === parseInt(rank) ? 'secondary' : 'primary';
            buttons.push (
                <Button
                    fullWidth
                    size={'small'}
                    color={color}
                    variant={variant}
                    sx={{marginBottom: '6px'}}
                    onClick={()=>setPermissions(columnDeptID, rank)}
                >{rank}</Button>
            )
        })
        return buttons;
    }


    const user = new User();
    const userID = 10000;
    const urlKey = '';
    const strengthPermissions = { "depts": { "36": { "sectionID": 36, "managerLevel": 2000, "organizationID": 3, "organizationName": "Example Organization", "orgShortName": "XMPL", "organizationTypeID": 4, "orgLabel": "Verified High School", "deptID": 1, "urlKey": "F-DKx9plWJg", "label": "Strength & Conditioning", "teams": [ 35, 31 ], "active": 1 } }, "teams": { "31": { "sectionID": 31, "managerLevel": 2000, "organizationID": 3, "organizationName": "Example Organization", "orgShortName": "XMPL", "organizationTypeID": 4, "orgLabel": "Verified High School", "urlKey": "awt50h7E_8A", "label": "1st Team", "sportID": 6, "deptSections": [ { "deptID": 1, "sectionID": 36, "managerLevel": 2000 } ], "active": 1 }, "35": { "sectionID": 35, "managerLevel": 2000, "organizationID": 3, "organizationName": "Example Organization", "orgShortName": "XMPL", "organizationTypeID": 4, "orgLabel": "Verified High School", "urlKey": "lM3-jMTZfrQ", "label": "2nd Team", "sportID": 9, "deptSections": [ { "deptID": 1, "sectionID": 36, "managerLevel": 2000 } ], "active": 1 } } }

    const sportPermissions = { "depts": { "34": { "sectionID": 34, "managerLevel": 2000, "organizationID": 3, "organizationName": "Example Organization", "orgShortName": "XMPL", "organizationTypeID": 4, "orgLabel": "Verified High School", "deptID": 7, "urlKey": "paPLrj-wGpA", "label": "Athletes", "teams": [ 35, 31, 35 ], "overrideDeptName": "Coaching Staff", "overrideSectionID": 43, "override": 1, "dbTeams": [ 35 ], "active": 1 }, "43": { "sectionID": 43, "managerLevel": 2000, "organizationID": 3, "organizationName": "Example Organization", "orgShortName": "XMPL", "organizationTypeID": 4, "orgLabel": "Verified High School", "deptID": 2, "urlKey": "paPLrj-wGpA", "label": "Coaching Staff", "teams": [ 35, 31 ], "active": 1 } }, "teams": { "31": { "sectionID": 31, "managerLevel": 2000, "organizationID": 3, "organizationName": "Example Organization", "orgShortName": "XMPL", "organizationTypeID": 4, "orgLabel": "Verified High School", "urlKey": "awt50h7E_8A", "label": "1st Team", "sportID": 6, "deptSections": [ { "deptID": 2, "sectionID": 43, "managerLevel": 2000 } ], "active": 1 }, "35": { "sectionID": 35, "managerLevel": 100, "organizationID": 3, "organizationName": "Example Organization", "orgShortName": "XMPL", "organizationTypeID": 4, "orgLabel": "Verified High School", "urlKey": "lM3-jMTZfrQ", "label": "2nd Team", "sportID": 9, "deptSections": [ { "deptID": 7, "sectionID": 34, "managerLevel": 100 }, { "deptID": 2, "sectionID": 43, "managerLevel": 2000 } ], "active": 1 } } }

    const athletePermissions = { "depts": { "34": { "sectionID": 34, "managerLevel": 100, "organizationID": 3, "organizationName": "Example Organization", "orgShortName": "XMPL", "organizationTypeID": 4, "orgLabel": "Verified High School", "deptID": 7, "urlKey": "68km1OoDAtn", "label": "Athletes", "teams": [ 35, 31 ], "active": 1 } }, "teams": { "31": { "sectionID": 31, "managerLevel": 100, "organizationID": 3, "organizationName": "Example Organization", "orgShortName": "XMPL", "organizationTypeID": 4, "orgLabel": "Verified High School", "urlKey": "awt50h7E_8A", "label": "1st Team", "sportID": 6, "deptSections": [ { "deptID": 7, "sectionID": 34, "managerLevel": 100 } ], "active": 1 }, "35": { "sectionID": 35, "managerLevel": 100, "organizationID": 3, "organizationName": "Example Organization", "orgShortName": "XMPL", "organizationTypeID": 4, "orgLabel": "Verified High School", "urlKey": "lM3-jMTZfrQ", "label": "2nd Team", "sportID": 9, "deptSections": [ { "deptID": 7, "sectionID": 34, "managerLevel": 100 } ], "active": 1 } } }

    const {deptID} = buttonData;


    const MyPermissions=()=>{
        return (
        <div style={{border: '2px solid #f00', padding: '12px', borderRadius: '4px'}}>
            <Typography variant={'h3'}>MY PERMISSIONS</Typography>
            <PermissionsManager
                interfaceType={'self'}
                assignedUserPermissions={user.getPermissions()}
                profileUserID={user.getUserID()}
                urlKey={''}
                parentSubmitFunction={(permissionSubmissions)=>submitPermissionChange(permissionSubmissions)}
            />
        </div>
        )
    }


    return (
        <div>
            <Header header={'Permissions Tester'}/>
            <br/>
            <br/>
            <DebugTitleObj title={'My Permissions'} color={'#003'} obj={user.getPermissions()}/>
            <br/>
            <br/>




            <Typography variant={'h2'}>Permissions</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <PaperMargin small={true}>
                        <Typography variant={'h3'}>
                            User Strength Coach
                        </Typography>
                        {renderTestButtons(1)}

                        <MyPermissions/>


                        <PreferencesAccordion
                            summaryText={'Strength Coach'}
                            disabled={deptID !== 1}
                            defaultExpanded={true}
                        >
                            <PermissionsManager
                                interfaceType={userID === user.getUserID() ? 'self' : 'permissions'}
                                assignedUserPermissions={strengthPermissions}
                                profileUserID={userID}
                                urlKey={urlKey}
                                parentSubmitFunction={(permissionSubmissions)=>submitPermissionChange(permissionSubmissions)}
                            />
                        </PreferencesAccordion>



                    </PaperMargin>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <PaperMargin small={true}>
                        <Typography variant={'h3'}>
                            User Sport Coach
                        </Typography>
                        {renderTestButtons(2)}

                        <MyPermissions/>

                        <PreferencesAccordion
                            summaryText={'Sport Coach'}
                            disabled={deptID !== 2}
                            defaultExpanded={true}
                        >
                            <PermissionsManager
                                interfaceType={userID === user.getUserID() ? 'self' : 'permissions'}
                                assignedUserPermissions={sportPermissions}
                                profileUserID={userID}
                                urlKey={urlKey}
                                parentSubmitFunction={(permissionSubmissions)=>submitPermissionChange(permissionSubmissions)}
                            />
                        </PreferencesAccordion>

                    </PaperMargin>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <PaperMargin small={true}>
                        <Typography variant={'h3'}>
                            User Athlete
                        </Typography>
                        {renderTestButtons(7)}

                            <MyPermissions/>

                        <PreferencesAccordion
                            summaryText={'Athlete'}
                            disabled={deptID !== 7}
                            defaultExpanded={true}
                        >

                        <PermissionsManager
                            interfaceType={userID === user.getUserID() ? 'self' : 'permissions'}
                            assignedUserPermissions={athletePermissions}
                            profileUserID={userID}
                            urlKey={urlKey}
                            parentSubmitFunction={(permissionSubmissions)=>submitPermissionChange(permissionSubmissions)}
                        />
                        </PreferencesAccordion>

                    </PaperMargin>
                </Grid>
            </Grid>



            <Typography variant={'h2'} sx={{marginTop: '24px'}}>Invitations</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <PaperMargin small={true}>
                        <Typography variant={'h3'}>
                            User Strength Coach
                        </Typography>
                        <PreferencesAccordion
                            summaryText={'User:Strength Coach'}
                            disabled={deptID !== 1}
                            defaultExpanded={true}
                        >

                            <PermissionsManager
                                interfaceType={'invite'}
                                assignedUserPermissions={strengthPermissions}
                                profileUserID={userID}
                                urlKey={urlKey}
                                parentSubmitFunction={(inviteData)=>submitInvitation(inviteData)}
                            />

                        </PreferencesAccordion>

                    </PaperMargin>

                </Grid>
                <Grid item xs={12} sm={4}>
                    <PaperMargin small={true}>
                        <Typography variant={'h3'}>
                            User Sport Coach
                        </Typography>

                        <PreferencesAccordion
                            summaryText={'User:Sport Coach'}
                            disabled={deptID !== 2}
                            defaultExpanded={true}
                        >

                            <PermissionsManager
                                interfaceType={'invite'}
                                assignedUserPermissions={sportPermissions}
                                profileUserID={userID}
                                urlKey={urlKey}
                                parentSubmitFunction={(inviteData)=>submitInvitation(inviteData)}
                            />

                        </PreferencesAccordion>

                    </PaperMargin>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <PaperMargin small={true}>
                        <Typography variant={'h3'}>
                            User Athlete
                        </Typography>

                        <PreferencesAccordion
                            summaryText={'User:Athlete Invitation'}
                            disabled={deptID !== 7}
                            defaultExpanded={true}
                        >

                        <PermissionsManager
                            interfaceType={'invite'}
                            assignedUserPermissions={athletePermissions}
                            profileUserID={userID}
                            urlKey={urlKey}
                            parentSubmitFunction={(inviteData)=>submitInvitation(inviteData)}
                        />

                        </PreferencesAccordion>

                    </PaperMargin>
                </Grid>
            </Grid>




        </div>
    )

}

const mapStateToProps=(state)=>{
    return {FITUser: state.FITUser};
}

export default connect(mapStateToProps)(PMTester);