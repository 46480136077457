import React from 'react';
import TabSection from "../TabSection";

const PrivacyPolicy=()=>{
    return (
        <div>
            Privacy Policy Coming Soon.
        </div>
    )
}

export default PrivacyPolicy;