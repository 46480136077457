import React, {useState, useMemo, useEffect} from 'react';
import User from 'fit/system/User';
import {
    PERM_DEPT,
    PERM_TEAM,
    PERMISSIONS_SCOPE,
    ICON_SX,
    ATHLETE_DEPT_ID,
    ORGANIZATION_TYPES,
    SCHOOL_ORGANIZATION_TYPE_IDS,
    DEPT_MINIMUM_REQUIRED_LEVELS,
    ASSISTANT_LEVEL,
    MOMENT_DATE_FORMAT,
    OPERATIONS_LEVEL,
} from "fit/system/FITConstants";
import {
    Button,
    Collapse,
    Divider,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    MenuItem,
    Grid,
    Radio,
    FormGroup
} from '@mui/material';
import {
    boolToInt,
    DebugTitleObj,
    getOrganizationFromPermissionEntry,
    getSpacing,
    isEmpty
} from "fit/system/UtilityFunctions";
import PreferencesAccordion from "../../PreferencesAccordion";
import {Business, CheckCircle, Warning} from "@mui/icons-material";
import DeptEntry from "./DeptEntry";
import {TextField, Typography} from "@mui/material";
import DebounceTextField from "../../Form/DebounceTextField";
import Alert from "../../Alert";
import CloseButton from "../../InterfaceButtons/CloseButton";
import Modal from "../../Dialogs/Modal";
import AthleteEligibilityForm from "../AthleteEligibilityForm";
import PaperMargin from "../../PaperMargin";
import MeasurementUnits from "fit/system/MeasurementUnits";
import UserPreferences from "fit/system/UserPreferences";
import SecureConnect from "fit/system/SecureConnect";

import moment from "moment";
import {connect} from "react-redux";
import * as yup from "yup";
import {Form, Formik} from "formik";
import TeamEntry from "./TeamEntry";


/*
    type: 3 possibilities: self, permissions, invite
        -self for viewing your own profile. displays all permissions. Allow removal of depts/teams
        -permissions: For managing an underling from the profile
        -invite: form for adding/inviting a user to the team

 */

const initTeamSwapData =(activate = false, teamOptions, activeSubmission)=>{
    const options = activate ? teamOptions : [];
    const submission = activate ? activeSubmission : {};
    const teamID = activate ? parseInt(activeSubmission.teamSectionID) : null;
    return {
        swapOption: 1, // 1 = swap with existing team, 0 = disable
        swapValue: null, //the team they want to activate when swapping
        teamOptions: options, //list of options for swapValue
        activeSubmission: submission, //current submission that gets sent to the server
        deactivatedTeamID: teamID //the team that's being deactivated. Used for disabling the selection
    }
}


const getInviteExpirationString=(expiration)=>{
    const userPrefs = new UserPreferences();
    const string = moment().add(expiration, 'days').format(MOMENT_DATE_FORMAT);
    return userPrefs.convertDate(string);
}

const initInviteFormData=()=>{
    const defaultExpiration = 7;
    return {
        title: '',
        invitationMessage: '',
        maxLimit: 1,
        expiration: defaultExpiration,
        expirationString: getInviteExpirationString(defaultExpiration),
    }
}


const PermissionsManager=({interfaceType, assignedUserPermissions, profileUserID, parentSubmitFunction, urlKey, deviceAccount = false})=>{

    //Required Level to manager user permissions
    const REQUIRED_LEVEL = OPERATIONS_LEVEL;

    const displayDebugData = false;


    //Permissions management
    const [sharedOrganizations, setSharedOrganizations] = useState({all: [], staff: []}); //list or shared organizationIDs: [1,2,3..]
    const [sectionScopes, setSectionScopes] = useState({});
    const [intersections, setIntersections] = useState({depts: {}, teams: []}); //{deptSectionID: [teams]}

    //dialogs and warnings
    const [permissionWarnings, setPermissionWarnings] = useState(1);
    const [displayModal, setDisplayModal] = useState(false);
    const [modalSection, setModalSection] = useState(null);
    const removingSelfWarning = 'removingSelf';
    const removingDeptWarning = 'removingUserDept';
    const removingTeamWarning = 'removingTeamOverlapRequired';
    const lastTeamWarning = 'removingLastTeam';



    //TeamSwap, last team warning (for depts with deptAdditions, team Additions)
    const [teamSwapData, setTeamSwapData] = useState(initTeamSwapData(false));
    const [pendingSubmissionData, setPendingSubmissionData] = useState({}); //stored submission data when displaying a modal
    const [submitPending, setSubmitPending] = useState(false);

    //Invite settings
    const [activePermissions, setActivePermissions] = useState([]);
    const [existingPermissions, setExistingPermissions] = useState([]); //Permissions that are already active (prevent inviting to already active depts/teams)
    const [inviteValid, setInviteValid] = useState(false);
    const [inviteFormData, setInviteFormData] = useState(initInviteFormData);
    const [pendingServerInvites, setPendingServerInvites] = useState([]); //list of invites that are pending (disable these within the form)

    //Athlete settings for invites
    const [schoolAthlete, setSchoolAthlete] = useState(false);
    const [athleteAffiliations, setAthleteAffiliations] = useState([]);

    const interfaceSelf = interfaceType === 'self';
    const interfaceInvite = interfaceType === 'invite';
    const interfacePermissions = !interfaceSelf && !interfaceInvite;

    const user = new User();
    const myDepts = user.getPermissions(PERM_DEPT);
    const myTeams = user.getPermissions(PERM_TEAM);


    const userSet = profileUserID != null;
    const myDeptsJSON = JSON.stringify(myDepts);
    const assignedJSON = JSON.stringify(assignedUserPermissions);

    const expirationOptions = [
        {label: '1 Day', value: 1},
        {label: '3 Days', value: 3},
        {label: '1 Week', value: 7},
        {label: '2 Weeks', value: 14},
        {label: '3 Weeks', value: 21},
        {label: '30 Days', value: 30}
    ];

    const getPendingInvites=()=>{
        /*
            -Get a list of pending invitations for the user based on the editor's manager depts
            -Requirements: interfaceInvite, userSet = true, must have OPERATIONS depts
         */
        if(!interfaceInvite || !userSet){
            // NOT interfaceInvite and user isn't set
            return;
        }

        const depts = user.getManagerDepts(REQUIRED_LEVEL);
        if(isEmpty(depts)){
            //User doesnt have the ability to manage invites. Terminate teh function
            return;
        }
        const sc = new SecureConnect(`invite.php?action=getPendingInvites&userID=${profileUserID}`);
        sc.setDisplayNotifications(true);
        sc.connect().then(json=>{
            if(sc.getCompleted(json)){
                //pending invitations exist for the user based on the editor's permissions
                //set the pending permissions {deptSectionID, teamSectionID}
                setPendingServerInvites(sc.getData(json));
            }
        })

    }


    const getRealDatabaseTeams=(dept)=>{
        //For when a coach get assigned to athletes depts but is also assigned to overridden teams
        //check for dbTeams field.
        if(dept.override && dept.dbTeams && dept.dbTeams.length > 0){
            return dept.dbTeams;
        }
        return false;
    }


    const organizePermissions=(deptPermissions)=>{
        let permissions = [];
        Object.keys(deptPermissions).forEach(deptIndex =>{
            const d = deptPermissions[deptIndex];
            let obj = {
                sectionID: parseInt(d.sectionID),
                managerLevel: parseInt(d.managerLevel),
                organizationID: parseInt(d.organizationID),
                organizationTypeID: parseInt(d.organizationTypeID),
                orgShortName: d.orgShortName,
                teams: d.teams,
                dbTeams: false,
            }
            //Department overridden by another. Set values.
            if(d.override){
                obj.override = parseInt(d.override);
                obj.overrideDeptName = d.overrideDeptName;
                obj.overrideSectionID = parseInt(d.overrideSectionID);
                obj.dbTeams = getRealDatabaseTeams(d);
            }
            permissions.push(obj);
        })
        return permissions;
    }
    const getMyDept=(deptSectionID)=>{
        return getDept(deptSectionID, 'myDepts');
    }

    const getDept=(deptSectionID, group = 'myDepts')=>{
        let list;
        if(group === 'existing'){
            list = existingPermissions;
        } else if(group === 'active'){
            list = activePermissions
        } else{
            //my depts
            list = myDepts;
        }
        const dept = list.find(d => parseInt(d.sectionID) === parseInt(deptSectionID));
        if(dept){
            return dept;
        }
        return {organizationID: null, organizationTypeID: null, deptID: null, teams: []};
    }
    const getDeptIndex=(deptSectionID, group = 'active')=>{
        const list = group === 'active' ? activePermissions : existingPermissions;
        return list.findIndex(i => parseInt(i.sectionID) === parseInt(deptSectionID));
    }
    const getDeptRankDefault=(deptID)=>{
        const level = DEPT_MINIMUM_REQUIRED_LEVELS.find(d=> parseInt(d.deptID) === parseInt(deptID));
        if(level){
            return level.minimum;
        }
        //Return default assistant level
        return ASSISTANT_LEVEL;
    }

    const handleInviteNote=(value)=>{
        let form = {...inviteFormData};
        form.invitationMessage = value;
        setInviteFormData(form);
    }

    const submitPermissionWrap=(submission)=>{
        //Wrap the permission submission in an array if it isn't already
        if(interfaceInvite){
            //Invite pass through. No need to group into submissions
            //invites have their own data structure
            parentSubmitFunction(submission);
            return;
        }
        const submissionArray = submission.length != null ? submission : [submission];
        if(parentSubmitFunction != null){
            parentSubmitFunction(submissionArray);
        }
    }

    const handleInviteData=(field, value)=>{
        let form = {...inviteFormData};
        if(field === 'maxLimit'){
            if(`${value}`.length > 0) {
                const mUnits = new MeasurementUnits();
                value = parseInt(mUnits.handleNumericValue(value, 0));
                value = value > 1 ? value : 1;
                value = value < 150 ? value : 150; //Max value is 150
                form[field] = value;
            } else{
                form[field] = '';
            }
        } else if(field === 'expiration'){
            form[field] = value;
            form.expirationString = getInviteExpirationString(parseInt(value));
        } else {
            form[field] = value;

        }

        setInviteFormData(form);

    }

    const handleSwapData=(field, value)=>{
        let tsd = structuredClone(teamSwapData);
        switch(field){
            case 'swapOption':
                tsd.swapOption = value;
                break;
            case 'swapValue':
                tsd.swapValue = value;
                break;
        }
        setTeamSwapData(tsd);
    }

    const submitPendingChanges=()=>{
        //Submit pending changes after the warning
        if(isEmpty(pendingSubmissionData)){
           return;
        }
        submitPermissionWrap(pendingSubmissionData);
        //clear out the pending submission
        setPendingSubmissionData({});
    }
    const setLastTeamWarning=(action, submission)=>{
        /*
            Handles removing the last team for a department (with department/team additions on)
            if action === warn: display the modal, save the submission for processing
            else: get the team option
         */

        if(action === 'warn') {
            //Warn the user. Display the modal. Set the team options
            //Get the team list for the department
            const {teams} = getMyDept(submission.deptSectionID);
            //get all of the information for the teams
            const teamList = myTeams.filter(t => teams.includes(parseInt(t.sectionID)));
            setTeamSwapData(initTeamSwapData(true, teamList, submission));
            setDisplayModal(true);
            setModalSection(lastTeamWarning);
        } else if(action === 'submit'){
            //Submitting Team swap data for another user
            if(modalSection !== lastTeamWarning){
                //Submissions only apply to last Team Warnings
                //If the modal doesnt match the Last Team Warning, then terminate.
                return;
            }
            const tsd = teamSwapData;
            const {swapOption, activeSubmission, swapValue} = tsd;
            if(swapOption === 0){
                //Disabling the team...send to the submission
                submitPermissionWrap(activeSubmission);
            } else{
                if(isEmpty(activeSubmission)){
                    return;
                }
                //Swapping the team.
                //Add the new team to existing submissions
                const {deptSectionID} = activeSubmission;
                const swapSubmission = {
                    deptSectionID,
                    teamSectionID: swapValue,
                    active: 1
                }
                const newSubmission = [
                    swapSubmission,
                    activeSubmission
                ]
                submitPermissionWrap(newSubmission);
            }
            //Close the modal. reset variables
            setTeamSwapData(initTeamSwapData(false));
            setDisplayModal(false);
        } else{
            //Close the modal. Reset variables
            setTeamSwapData(initTeamSwapData(false));
            setDisplayModal(false);
        }
    }



    //onload, editor's, or user's permissions updated (from the profile).
    useEffect(()=>{
        if(interfaceSelf){ // isEmpty(assignedUserPermissions) ||
            //user is viewing own profile. No need for scopes. Full access to permissions
            //user.getPermission(PERM_DEPT)
            setActivePermissions(organizePermissions(myDepts));
            return;
        }

        let permissions = [];
        let sharedOrgs = [];
        let staffOrgs = [];
        let sharedDepts = {};
        let sharedTeams = [];
        let scopes = {};
        const myOrganizationIDs = user.getOrganizationIDs();
        const myTeamIDs = myTeams.map(t => parseInt(t.sectionID));
        //Pull the scopes for individual departments
        //scopes determine whether form elements can be displayed based on the interface type
        try {
            myDepts.forEach(dept => {
                const {sectionID} = dept;
                const scope = getSectionScope(dept);
                if (scope) {
                    scopes[sectionID] = scope;
                }
            })
        } catch (e) {
            console.warn('SCOPES FAILED');
            console.error('SCOPES FAILED');
            console.error(e);
        }

        try{
            //Run through user's departments
            const deptKeys = Object.keys(assignedUserPermissions[PERM_DEPT]);
            deptKeys.forEach(sectionID => {
                const dept = assignedUserPermissions[PERM_DEPT][sectionID];
                const {organizationID, deptID, teams} = dept;
                const myDeptIndex = myDepts.findIndex(d => parseInt(d.sectionID) === parseInt(sectionID));
                const deptFound = myDeptIndex > -1;
                const staffDepartment = checkStaffDept(deptID);
                //Determine if the organization is shared between the editor and user
                const orgShared = myOrganizationIDs.includes(organizationID);



                if(orgShared){
                    //Organization shared
                    //These are deliberately 2 separate statements rather than 1.
                    //If a staff or athlete dept comes up first, it could negate other depts
                    if(!sharedOrgs.includes(organizationID)) {
                        //add to shared orgs if not already included
                        sharedOrgs.push(organizationID);
                    }
                    if(staffDepartment && !staffOrgs.includes(organizationID)){
                        //Add to staff org if not already included
                        staffOrgs.push(organizationID);
                    }

                    //Add the scope for the department
                    const scope = getSectionScope(dept);
                    if(false) {
                        console.log('USER DEPT', sectionID, orgShared);
                        console.log('USER DEPT ORG SHARED', sectionID, 'SCOPE = ', scope);
                    }
                    if(scope && scopes[sectionID] == null){
                        //Only add in the scope if it doesn't already exist
                        scopes[sectionID] = scope;
                    }
                }
                //Find team Intersections outside of deptIntersections
                //Necessary if the user is an athlete and the editor/viewer is a Strength coach
                //Will aid in the displaying the department, but will disable the viewer from having access to the permissions (disable elements)
                const teamIntersections = teams.filter(t=> myTeamIDs.includes(t));
                const teamsIntersect = teamIntersections.length > 0;

                //Dept overlap found
                if (deptFound && teamsIntersect) {
                    //There's dept overlap and team overlap between the editor and user
                    sharedDepts[sectionID] = teamIntersections;
                }
                if(teamsIntersect){
                    sharedTeams = sharedTeams.concat(teamIntersections);
                }
                permissions.push(dept);
            })
        } catch (e){
            console.warn('NO ASSIGNED PERMISSIONS');
            console.error('NO ASSIGNED PERMISSIONS');
            console.error(e);
        }


        //Set section scopes for both myPermissions and userPermissions
        setSectionScopes(scopes);

        //List of organization IDs that are shared
        setSharedOrganizations({all: sharedOrgs, staff: staffOrgs});
        //Intersections between the editor and the user
        setIntersections({depts: sharedDepts, teams: sharedTeams});

        if(interfaceInvite && userSet){
            setExistingPermissions(permissions);
        } else{
            setActivePermissions(permissions);
        }

    },[assignedJSON, myDeptsJSON]);

    //Validate user invitation (will disable the invite button)
    useEffect(()=>{
        if(!interfaceInvite){
            setInviteValid(true);
            return;
        }
        let valid = !isEmpty(activePermissions);
        for(let k=0; k<activePermissions.length; k++){
            const invite = activePermissions[k];
            if(invite.teams.length === 0){
                //no teams found
                valid = false;
                break;
            }
        }

        //Must have at least 1 active dept and 1 active team
        if(valid && schoolAthlete){
            for(let k =0; k < athleteAffiliations.length; k++){
                const aff = athleteAffiliations[k];
                const {expirationDate, yearLabelID} = aff;
                if(parseInt(yearLabelID) === 0 || expirationDate == null){
                    //Affiliation not assigned. invalid invite.
                    valid = false;
                    break;
                }
            }
        }
        //check affilations
        setInviteValid(valid);
    },[activePermissions, athleteAffiliations]);

    //Onload: getPending (already submitted) invitations for the user
    useEffect(()=>{
        //only runs for interfaceInvites
        getPendingInvites();
    },[]);

    const setModal=(type)=>{
        setDisplayModal(true);
        setModalSection(type);
    }

    const getOrganizationListings=()=>{

        //Return a list of organizations for inviting/modifying permissions
        let orgs = [];
        let orgIndexLookup = {};
        let actionalDeptSections = [];
        const unverifiedOrganizationTypes = [ORGANIZATION_TYPES.unofficialUniversity, ORGANIZATION_TYPES.unofficialHighSchool];
        myDepts.forEach(d =>{
            const {organizationID, organizationTypeID, sectionID} = d;
            //Has the organization already been added?
            const newOrganization = orgIndexLookup[organizationID] == null;
            //Is the organization unverified? This will prevent invitations from the user profile.
            const unverifiedOrg = unverifiedOrganizationTypes.includes(parseInt(organizationTypeID));
            //Prevent unverified organizations from inviting users from the user profile
            //Allows for displaying of unverified organizations for interfaceSelf and permissions
            const invitationsPermitted = !interfaceInvite || (!userSet || (userSet && !unverifiedOrg));
            //Verifies that the editor MUST share the organization with the user for interfacePermissions
            const {display, withinScope} = checkDisplayDepartment(d);

            if(display){
            //if(interfaceSelf || (sharedOrgCheck && display && staffCheck)){
                if(newOrganization){
                    //Create the organization entry
                    const org = createDepartmentOrganizationEntry(d, !withinScope, invitationsPermitted);
                    orgIndexLookup[organizationID] = orgs.length;
                    orgs.push(org);
                }

                const orgIndex = orgIndexLookup[organizationID];
                if(withinScope === true) {
                    orgs[orgIndex].disabled = false;
                }
                orgs[orgIndex].deptEntries.push({
                    ...d, //add department entry
                    withinScope //within permissions scope (enables or disables the dept)
                })
                //Add the dept's sectionID to the list
                //will be checked against later on for interfacePermissions
                actionalDeptSections.push(sectionID);
            }

        });

        if(false) {
            console.log('----ALL ORGS ---');
            console.log(orgIndexLookup);
            console.log(orgs);
        }
        //Return the user permissions options for self and invitations
        if(!interfacePermissions || (interfacePermissions && !userSet)){
            return orgs;
        }



        //Interface Permissions: Run through user depts and determine if they should be displayed too.
        //Find additional orgs to display
        //No organization checks required because the department entries are based on shared teams
        const sharedTeamDepts = activePermissions.filter(p =>
            //Shared Teams exist between the editor & user
            p.teams.filter(t => intersections.teams.includes(t)).length > 0
            //Not previously assigned
            && actionalDeptSections.includes(p.sectionID) === false
        );

        if(isEmpty(sharedTeamDepts)){
            //Return organizations
            return orgs;
        }

        if(false) {
            console.log('--------------- USER DEPTS -----------------');
            console.log('***ASSIGNED USER PERMISSIONS', assignedUserPermissions);
            console.log('SHARED TEAM DEPTS', sharedTeamDepts);
            console.log('SCOPES', sectionScopes);
            console.log('***');
        }
        sharedTeamDepts.forEach(u =>{
            const {sectionID, organizationID} = u;
            const orgSet = orgIndexLookup[organizationID] != null;
            const {display, withinScope} = checkDisplayDepartment(u);
            if(display){
                if(!orgSet){
                    //Create the organization entry
                    const org = createDepartmentOrganizationEntry(u, true, false); //Disabled because the department isn't shared, but the team is
                    orgIndexLookup[organizationID] = orgs.length;
                    orgs.push(org);
                }
                const orgIndex = orgIndexLookup[organizationID];
                u.withinScope = withinScope;
                orgs[orgIndex].deptEntries.push({
                    ...u, //the user's department entry
                    withinScope //ability to modify the department
                });
                actionalDeptSections.push(sectionID);
            }
        })
        //Return organizations
        return orgs;
    }

    const createDepartmentOrganizationEntry=(departmentEntry, disabled, invitationsPermitted)=>{
        let org = getOrganizationFromPermissionEntry(departmentEntry);
        //Disable the entry if it's an unverified organization on the profile page
        org.disabled = Boolean(disabled);
        org.invitesEnabled = Boolean(invitationsPermitted);
        org.withinScope = true;
        org.deptEntries = [];
        return org;
    }

    const getSectionScope=(department, ignoreManagerLevel = false)=>{
        const {organizationTypeID, deptID} = department;
        const managerLevel = parseInt(department.managerLevel);
        let scope = false;
        //Find the corresponding permission scope based on organizationType, departmentID and managerLevel
        const scopeEntries = PERMISSIONS_SCOPE.filter(p =>
            //department within specified organization types
            p.orgTypes.includes(parseInt(organizationTypeID))
            //Department matches
            && parseInt(p.deptID) === parseInt(deptID)
            //rank met for the editor's department
            && (parseInt(p.minLevel) <= managerLevel) //  || ignoreManagerLevel === true
        );
        if(isEmpty(scopeEntries)){
            //No Scope? Nothing to return
            return scope;
        }
        if(false) {
            console.log('---------------------------');
            console.log(organizationTypeID, deptID, '=>', managerLevel, 'P SCOPES', PERMISSIONS_SCOPE);
            console.log('YAY FOUND', scopeEntries);
        }

        //Find the options based on the manager's rank
        //AD's may be able to add depts/teams on the fly without invites
        //Coaches/ops are required to invite users
        let currentLevel = 0;
        scopeEntries.forEach(s =>{
            const minimumRank = parseInt(s.minLevel);
            if(managerLevel >= minimumRank && minimumRank > currentLevel){
                currentLevel = minimumRank;
                scope = s;
            }
        });
        return scope;
    }
    const checkAthlete=(deptID)=>{
        return parseInt(deptID) === ATHLETE_DEPT_ID;
    }
    const checkStaffDept=(deptID)=>{
        return !checkAthlete(deptID);
    }
    const setNewDept=(deptSectionID, pending)=>{
        //Create a department entry for new permissions
        //This will apply to interfacePermissions for Directors adding new depts to staff
        //Will also apply to interfaceInvites
        const dept =  getMyDept(deptSectionID);
        const {organizationID, organizationTypeID, deptID} = dept;
        return {
            sectionID: deptSectionID,
            managerLevel: getDeptRankDefault(deptID),
            organizationID: parseInt(organizationID),
            organizationTypeID: parseInt(organizationTypeID),
            interfacePermissionPending: pending, //new dept being added by a director?
            teams: [],
        }
    }
    const removeNewDepartment=(deptSectionID)=>{
        //Remove the department from activePermissions
        const deptIndex = getDeptIndex(deptSectionID);
        let ap = [...activePermissions];
        ap.splice(deptIndex, 1);
        setActivePermissions(ap);
    }
    const checkPendingDept=(deptSectionID)=>{
        //Determine if the department is pending submission to the server:
        //Always true for invites
        //Will be true if an AD is adding a department for someone under their umbrella
        const deptIndex = getDeptIndex(deptSectionID);
        const dept = activePermissions[deptIndex];
        return dept.interfacePermissionPending === true;
    }
    const modifyPendingRank=(deptSectionID, rankLevel)=>{
        //Updates the active permissions rank for a pending department
        let ap = [...activePermissions];
        const deptIndex = getDeptIndex(deptSectionID);
        //console.log('UPDATING INVITES', deptSectionID,':',rankLevel,'=>',deptIndex,ap[deptIndex]);
        ap[deptIndex].managerLevel = parseInt(rankLevel);
        setActivePermissions(ap);
    }
    const editRank=(deptSectionID, rankLevel)=>{
        //Edit the rank of a department
        if(interfaceSelf){
            //user may not update rank for the self
            return;
        }
        if(interfacePermissions){
            const pending = checkPendingDept(deptSectionID);
            if(pending){
                //New pending department. Not yet sent to server
                modifyPendingRank(deptSectionID, rankLevel);
            } else {
                //Updating the rank for an existing dept permission.
                //Send to user profile the new rank update
                submitPermissionWrap({deptSectionID, managerLevel: rankLevel, active: 1});
            }
        } else{
            //Invites -OR- new pending department. Update the invite/pending state for the user
            modifyPendingRank(deptSectionID,  rankLevel);
        }
    }
    const toggleSection=(type, deptSectionID, teamSectionID, on)=>{
        const submission = {deptSectionID, teamSectionID, active: boolToInt(on)};
        let ap = [...activePermissions];
        deptSectionID = parseInt(deptSectionID);
        teamSectionID = parseInt(teamSectionID);
        const warningsActive = parseInt(permissionWarnings) === 1;
        if(interfaceSelf){
            if(on === true){
                //Impossible action: Editors cant add permissions to themselves
                //Should never occur. Defensive programming anyway.
                return;
            }
            //Removing teams from themselves. All
            let selfSubmission;
            if(type === PERM_TEAM){
                selfSubmission = submission;
            } else{
                //User is removing their own permissions. Quitting.
                //Remove all teams under a department.
                //System will remove the department once all teams are removed.
                try{
                    const {teams} = myDepts.find(i=>parseInt(i.sectionID) === parseInt(deptSectionID));
                    selfSubmission = teams.map(t=> {
                        return {
                            deptSectionID,
                            teamSectionID: t,
                            active: 0
                        }
                    })
                } catch (e){
                    //No department found can't apply teams. Die.
                    console.error('User Quitting Department: No Department Found To remove.')
                    console.error(e);
                    return;
                }
            }
            //Determine whether the submission goes to the pendingSubmission Queue or gets sent to the server
            if(warningsActive){
                //Alerts on. Display an alert to the user
                setModal(removingSelfWarning);
                setPendingSubmissionData(selfSubmission);
            } else{
                //Alerts off. Send to the server
                submitPermissionWrap(selfSubmission);
            }
        } else if(interfacePermissions){
            //USER PERMISSIONS
            //Toggling on/off teams, depts
            //get team count for depts and teams.
            const scope = sectionScopes[deptSectionID];
            const {teamAdditions, deptAdditions} = scope;

            //Broken down into on/off (For displaying the necessary warnings)
            if(!on){
                //Toggling off: Broken down into depts and teams

                const dept = getDept(deptSectionID, 'active');
                if(type === PERM_DEPT){
                    //Toggling off entire department
                    //Determine if the existing record is pending
                    if(checkPendingDept(deptSectionID)){
                        //Department is pending.
                        //Toggle solely exists within state.
                        //Hasn't been sent to the server.
                        //Just remove
                        removeNewDepartment(deptSectionID);
                        return;
                    }
                    //removing all teams under a department
                    let pullList = false;
                    try{
                        const intersectionTeams = intersections.depts[deptSectionID];
                        pullList = intersectionTeams.map(t =>{
                            return {
                                deptSectionID,
                                teamSectionID: t,
                                active: 0
                            }
                        })
                    } catch (e){
                        console.error('Pulling Department: No Team Intersections Available');
                        console.error(e);
                    }
                    if(!pullList){
                        //Nothing to pull. die.
                        return;
                    } else if(warningsActive){
                        //Create the warning
                        setModal(removingDeptWarning);
                        setPendingSubmissionData(pullList);
                    } else{
                        //Submit to the server
                        submitPermissionWrap(pullList);
                    }
                } else{
                    //Toggling off single team
                    if(teamAdditions && dept.teams.length === 1){
                        //Team Swap Logic applies
                       //When removing last team and team additions are allowed. Provide the option to swap teams.
                       //Turned on regardless of warnings.
                       //Display modal that they're removing the last team.
                       //Give options for swapping teams
                       //Requires teamCount === 1 ** teamAdditions === true
                       setLastTeamWarning('warn', submission);
                    } else if(warningsActive && !teamAdditions){
                        //Team swapping not active, but warnings are.
                        //Set the submission to pending
                        //Display the warning to the user
                        setPendingSubmissionData(submission);
                        setModal(removingTeamWarning);
                    } else{
                        //Warnings have been turned off.
                        //Submit to the server
                        submitPermissionWrap(submission);
                    }
                } // End Toggling Team OFF logic
                //end Toggling OFF Department/Team Logic
            } else{
                //The following logic is for activating departments and teams
                if(type === PERM_DEPT){
                    //AD toggling on a department. Set requirement for a team
                    //Add the department set pending to true
                    if(!deptAdditions){
                        //Departments can't be added. Defensive Programming.
                        //Must be an invitation. Die.
                        return;
                    }
                    ap.push(setNewDept(deptSectionID, true));
                    setActivePermissions(ap);
                } else{
                    //Adding a team to an existing department
                    /*
                        -For AD+ Ranks, SC Dept:
                        -Run check to see if the department submission hasn't been set
                        -If pending dept submission, send both the dept permission submission and the new team submission
                        -Else just send new team submission
                     */
                    if(!teamAdditions){
                        //Die. Defensive Programming. Teams can't be added.
                        return;
                    }
                    const departmentPending = checkPendingDept(deptSectionID);
                    if(departmentPending){
                        //Department is pending.
                        //Create a complete submission with both dept & team
                        const deptIndex = getDeptIndex(deptSectionID);
                        const dept = activePermissions[deptIndex];
                        const {sectionID, managerLevel} = dept;
                        const deptSubmission = {deptSectionID: sectionID, managerLevel, teamSectionID: null, active: 1};
                        const completeSubmission = [deptSubmission, submission];
                        //Submit the permission to the server
                        submitPermissionWrap(completeSubmission);
                        //remove the pending submission from active permissions
                        //removeNewDepartment(deptSectionID);
                        return;
                    } else{
                        //Department already exists. Just add the team.
                        //Adding teams permitted. No warnings needed. Submit to server.
                        submitPermissionWrap(submission);
                    }
                } //End Team Activation
            } //End ON/OFF Toggle Conditional
            //END OF PERMISSION (interfacePermissions) LOGIC HERE
        } else{
            //Invitations
            const dept =  getMyDept(deptSectionID);
            const {organizationID, organizationTypeID, deptID, organizationName} = dept;
            const orgValid = checkInviteOrganizationID(organizationID);
            const schoolAffiliated = SCHOOL_ORGANIZATION_TYPE_IDS.includes(parseInt(organizationTypeID));
            if(!orgValid){
                //Prevent addition of the department
                //Trying to add a department from another organization
                //Limit invitations to a single organization
                return;
            }
            if(type === PERM_DEPT && on){
                //Display alert requiring at least one team for submission
                ap.push(setNewDept(deptSectionID, false));
                setActivePermissions(ap);
                if(schoolAffiliated && checkAthlete(deptID)){
                    //Toggling on athlete
                    setSchoolAthlete(true);
                    initAffiliations(organizationID, organizationTypeID, organizationName, true);
                }
            } else if(type === PERM_DEPT && !on){
                //Toggling off dept
                removeNewDepartment(deptSectionID);
                if(schoolAffiliated && checkAthlete(deptID)){
                    //Toggling off athlete and clear out permissions
                    setSchoolAthlete(false);
                    initAffiliations(organizationID, null, '', false);
                }
            } else if(type === PERM_TEAM && on){
                //toggling on team
                const deptIndex = getDeptIndex(deptSectionID);
                if(deptIndex > -1){
                    ap[deptIndex].teams.push(teamSectionID);
                    setActivePermissions(ap);
                } else {
                    //user is already assigned to the department, and is being invited to a new team
                    //get the department from existing permissions and push to the active permissions.
                    const existingIndex = getDeptIndex(deptSectionID, 'existing');
                    let existing = structuredClone(existingPermissions[existingIndex]);
                    //Set the managerLeve of the existing Dept to the default managerLevel
                    //This is to circumvent problems of the user outranking the editor for the dept
                    existing.managerLevel = getDeptRankDefault(existing.deptID);
                    existing.teams = [teamSectionID];
                    ap.push(existing);
                    setActivePermissions(ap);
                }
            } else{
                //toggling of team
                const deptIndex = getDeptIndex(deptSectionID);
                const {teams} = activePermissions[deptIndex];
                const teamIndex = teams.findIndex(t => parseInt(t) === teamSectionID);
                ap[deptIndex].teams.splice(teamIndex, 1);
                setActivePermissions(ap);
            }
        } // End self, permissions, invites check
    }

    const checkDisplayDepartment=(department)=>{
        /*
            Determines whether to display the department and whether managing permissions is 'within scope' of the editor
            Criteria for displaying a department:
            -InterfaceSelf: Always
            -InterfaceInvite: editor rank meets required level
            -InterfacePermissions: Dependent on permissionScope, editor's Rank, and teamIntersections
                -If Editor's Rank && permissionScope allow deptAdditions : display
        */
        const noAccess = {display: false, withinScope: false};
        const displayOnly = {display: true, withinScope: false};
        const enabled = {display: true, withinScope: true};
        const {sectionID, managerLevel, deptID, organizationID, teams} = department;
        const modificationRankMet = parseInt(managerLevel) >= REQUIRED_LEVEL;
        if(interfaceSelf || interfaceInvite && modificationRankMet){
            //Full Access
            return enabled;
        }
        if(interfaceInvite && modificationRankMet){
            //User Rank not met for invitations. Option isn't available
            return noAccess;
        }

        if(!interfacePermissions){
            //Fail safe for other invitation criteria that hasn't been met
            //Everything below will be permissions
            return noAccess;
        }

        /***********************************************/
        /*********** PERMISSIONS START HERE ************/
        /***********************************************/

        let scope = false;
        let deptAdditions = false;
        let teamAdditions = false;
        let assigned = false;
        try{
            scope = getSectionScope(department);
            deptAdditions = scope.deptAdditions;
            teamAdditions = scope.teamAdditions;
            assigned = assignedUserPermissions[PERM_DEPT][sectionID] != null;
        } catch (e){
            const noPerms = isEmpty(assignedUserPermissions);
            const string = noPerms ? 'Assigned Permissions Empty' : 'Section Scope Failed';
            console.warn(`SECTION ${sectionID}: Scope/Assigned: ${string}`);
        }


        const sharedOrganization = sharedOrganizations.all.includes(organizationID);
        const sharedDepartment = intersections.depts[sectionID] != null;
        const sharedTeams = teams.filter(t => intersections.teams.includes(t)).length > 0;

        //Only display staff departments for other staff (director exceptions)
        //Prevent directors from adding athletes to staff departments. Athletes need to be invited.
        //Will only display staff positions for personal permissions, invitations, or if the user is a member of the staff
        const staffMember = sharedOrganizations.staff.includes(organizationID);
        const staffDept = checkStaffDept(deptID);
        const athleteDept = !staffDept;

        //ALL THE CONDITIONS WHEN THE INTERFACE WONT DISPLAY DEPARTMENT PERMISSIONS
        //WILL RETURN NO ACCESS:
        if(
            //Cant display Department because there's no organizationl overlap
            !sharedOrganization ||
            //Prevent athletes from being assigned to staffing departments
            //Must be invited
            (staffDept && !staffMember) ||
            //Don't show athlete departments when they havent been assigned
            (athleteDept && !assigned) ||
            //Device Master can only be assigned to one department.
            //Hide all other departments that it isn't assigned to.
            (deviceAccount && !assigned)
        ){
            return noAccess;
        }

        //Prevent display of staff departments when departments can't be added
        //And the department isn't shared
        if(staffDept && staffMember && !sharedDepartment && !deptAdditions){
            //If the department is assigned to the user, display it.
            //Otherwise hide it from view
            return assigned ? displayOnly : noAccess;
        }


        //THE CONDITIONS FOR DISPLAYING DEPARTMENTS, BUT NOT ALLOWING EDITING
        if(
            //Display athlete departmental assignments for shared organizations
            //Even if the department isn't shared: Allow strength coaches to see athlete dept assignments
            (athleteDept && !sharedDepartment) ||
            //Rank wasn't met for the department
            //Display the department, but cant modify
            //For assistant coaches, etc
            !modificationRankMet ||
            //Shared organization but no department overlap
            //must have shared teams
            //Can only show the department/team combinations
            //No modification access allowed
            (!deptAdditions && !sharedDepartment && sharedTeams)
        ){
            return displayOnly;
        }

        //Departments are allowed to be assigned through permissions for staff members
        //no previous assignment necessary
        if(deptAdditions && staffMember){
         /*
                -NEED SOME SORT OF HAS RANK OVERLAP.
                -ONLY AVAILABLE IF THERE'S STAFF DEPARTMENTAL OVERLAP
           */
            return enabled;
        }

        //Shared department with the user
        //Dependent on team settings
        if(!deptAdditions && sharedDepartment){
            if(teamAdditions || (!teamAdditions && sharedTeams)){
                //Team options are available for the department
                return enabled;
            } else{
                //No shared teams and no team additions available.
                //No access available for this department
                return noAccess;
            }
        }
        //Default to nothing
        return noAccess;
    }

    const checkDisplayTeam=(deptSectionID, teamSectionID)=>{
        //Always display teams for self and invitation interfaces
        if(interfaceSelf || interfaceInvite){
            return true;
        }

        /***********************************************/
        /*********** PERMISSIONS START HERE ************/
        /***********************************************/

        const deptAssigned = assignedUserPermissions[PERM_DEPT][deptSectionID] != null;
        const userTeams = deptAssigned ? assignedUserPermissions[PERM_DEPT][deptSectionID].teams : [];
        const teamAssigned = userTeams.includes(teamSectionID);
        let teamAdditions = false;
        let scope = false;
        let teamIntersections = [];
        try{
            scope = sectionScopes[deptSectionID];
            teamAdditions = scope.teamAdditions;
        } catch (e){
            console.warn(`Scope not found for Org Section: ${deptSectionID}`);
        }

        if(teamAssigned || (teamAdditions && !teamAssigned)){
            return true;
        }

        if(!teamAdditions && !teamAssigned){
            return false;
        }


        //do something with team Intersections eventually?!?!
        if(false){
            try {
                //const fullIntersection = intersections.teams.includes(parseInt(teamSectionID));
                teamIntersections = intersections.depts[deptSectionID];
                const teamOverlap = teamIntersections.includes(parseInt(teamSectionID));
                return teamOverlap;
            } catch (e) {

            }
        }
        return false;
    }
    const getTeamChecked=(deptSectionID, teamSectionID, assignedPermissions, existingInviteAssignment)=>{
        //Determine if a team entry has been checked/Selected
        let inviteTeamExists = false;
        let checked = false;
        if(isEmpty(activePermissions) && isEmpty(existingPermissions) && isEmpty(pendingServerInvites)){
            return {checked, inviteTeamExists};
        }
        if(interfaceSelf){
            //All team permissions will be checked for self interface.
            //Teams will be removed by system when toggled off
            return {checked: true, inviteTeamExists};
        }
        try{
            let teamList;
            teamSectionID = parseInt(teamSectionID);
            if(interfaceInvite){
                //Merge both active (new) teams and existing teams together
                const newAssignmentExists = assignedPermissions && assignedPermissions.managerLevel != null;
                const deptAssignmentAlreadyExists = existingInviteAssignment.managerLevel != null;
                const newTeams = newAssignmentExists ? assignedPermissions.teams : [];
                const existingTeams = deptAssignmentAlreadyExists ? existingInviteAssignment.teams: [];
                teamList = existingTeams.concat(newTeams);
                inviteTeamExists = deptAssignmentAlreadyExists && existingTeams.includes(teamSectionID)
            } else{
                //Interface permissions
                //Get what's currently assigned from activePermissions
                teamList = assignedPermissions.teams; //mergeUserTeamPermissions(assignedPermissions);
            }
            checked = teamList.includes(teamSectionID);

        } catch (e){
            console.warn('Assign teams not yet set?');
        }
        return {checked, inviteTeamExists};
    }


    //Invitations & Athlete affiliations
    const submitInvitation=()=>{
        //consolidate form data and submit
        let submissions = [];
        //Covert the permission tree into permission submissions
        activePermissions.forEach(p =>{
            const {sectionID, teams, managerLevel} = p;
            submissions.push({
                deptSectionID: sectionID,
                managerLevel,
                active: 1,
            });
            teams.forEach(teamSectionID=>
                submissions.push({
                    deptSectionID: sectionID,
                    teamSectionID,
                    active: 1
                })
            )
        })

        const affiliationData = athleteAffiliations.length > 0 ? athleteAffiliations[0] : {};
        const userKeySet = urlKey && urlKey.length > 0;

        const sc = new SecureConnect('invite.php', 'post');
        sc.setAction('createInvite');
        sc.setFormData({
            ...inviteFormData,
            ...affiliationData,
            submissions: JSON.stringify(submissions),
            userKey: userKeySet ? urlKey : ''
        });
        sc.connect().then(json =>{
            if(sc.getCompleted(json)){
                //Add to existing pending invitations
                let pi = [...pendingServerInvites];
                const newInvites = pi.concat(submissions);
                setPendingServerInvites(newInvites);
                //Clear submitted invitations
                setActivePermissions([]);
                setInviteFormData(initInviteFormData);
                if(!userKeySet && parentSubmitFunction != null){
                    //Close the modal for group invitations
                    parentSubmitFunction();
                }
            }
        })
    }
    const checkInviteOrganizationID=(organizationID)=>{
        //Limit department invitations/additions to a single organization
        //used by <PermissionsManager/> (prevent dept addition), < DeptEntry/> (Disabling the form element)
        if(!interfaceInvite || isEmpty(activePermissions)){
            return true;
        }
        return parseInt(activePermissions[0].organizationID) === parseInt(organizationID);
    }
    const checkPendingInvitations=(deptSectionID, teamSectionID = null)=>{
        deptSectionID = parseInt(deptSectionID);
        if(!interfaceInvite){
            return false;
        }
        if(teamSectionID == null){
            //Return whether the dept exists within pendingInvites dataset
            return pendingServerInvites.findIndex(p => parseInt(p.deptSectionID) === deptSectionID) > -1;
        } else{
            return pendingServerInvites.findIndex(p=> parseInt(p.deptSectionID) === deptSectionID && parseInt(p.teamSectionID) === parseInt(teamSectionID)) > -1;
        }
    }
    const initAffiliations=(organizationID, organizationTypeID, organizationName, active)=>{
        let affiliations = structuredClone(athleteAffiliations);
        organizationID = parseInt(organizationID);
        organizationTypeID = parseInt(organizationTypeID);
        //find whether the organization exists within the list of affiliations
        //if active it doesn't exist, add to the end
        //if exists and inactive, remove it
        const index = affiliations.findIndex(aff => parseInt(aff.organizationID) === organizationID);
        if(index > -1 && !active){
            //Deactivated - remove the affiliation
            affiliations.splice(index, 1);
        }
        if(index === -1 && active){
            //Doesn't exist, add it
            //Get the organizationTypeID from the organization
            //Create the Affiliation Record
            affiliations.push({
                name: organizationName,
                expirationDate: null,
                yearLabelID: 0,
                redShirt: 0,
                active: 1,
                organizationID: organizationID,
                organizationTypeID: organizationTypeID
            })
        }
        setAthleteAffiliations(affiliations);
    }
    const handleAthleteAffiliation=(arrayKey, affiliationData)=>{
        let affiliations = structuredClone(athleteAffiliations);
        affiliations.splice(arrayKey, 1, affiliationData);
        setAthleteAffiliations(affiliations);
    }


    const organizations = getOrganizationListings();
    if(false){
      //  organizations = useMemo(()=>getOrganizationListings(),[JSON.stringify(myDepts), JSON.stringify(assignedUserPermissions)]);
    }

    const margin = getSpacing();
    const mgTop = {marginTop: margin};
    const mgBtm = {marginBottom: margin};
    const mgTopSmall = {marginTop: getSpacing('small')}

    const iDontHaveDepartments = myDepts.length ===0;
    const modalButtonDisabled = modalSection === lastTeamWarning && teamSwapData.swapOption === 1 && teamSwapData.swapValue == null;
    const modalButtonText = 'Submit Changes';

    //Invitation validation
    const groupInvitation = interfaceInvite && !userSet;
    const groupValidation = yup.object().shape({
        title: yup.string()
            .trim()
            .min(5,'Title should be at least 5 characters long')
            .max(40,'Title is exceeds 40 characters')
            .required('Title required'),
         maxLimit: yup.number()
            .min(1, 'At least 1 user required')
            .max(150, 'Max of 150 exceeded')
            .required('Invitee limit required'),
        invitationMessage: yup.string()
            .trim()
            .min(5,'At least 5 characters required')
            .max(255, 'Maximum of 255 characters exceeded'),
    })
    const singleValidation = yup.object().shape({
        invitationMessage: yup.string()
            .trim()
            .min(5,'At least 5 characters required')
            .max(255, 'Maximum of 255 characters exceeded'),
    })
    const schema = groupInvitation ? groupValidation: singleValidation;

    const RadioWarningGroup =
        <FormGroup sx={mgTop}>
            <FormLabel>Toggle Future Warnings</FormLabel>
            <RadioGroup value={permissionWarnings}>
            <FormControlLabel
                control={<Radio color={'primary'} checked={permissionWarnings === 1} onChange={()=>setPermissionWarnings(1)}/> }
                label={'Keep alerting me with warnings'}
            />
            <FormControlLabel
                control={<Radio color={'secondary'} checked={permissionWarnings ===0} onChange={()=>setPermissionWarnings(0)}/> }
                label={'Disable permission alerts'}
            />
            </RadioGroup>
        </FormGroup>

    return (
        <>
            {displayDebugData &&
                <>
                    <DebugTitleObj obj={assignedUserPermissions} title={'PROPS: ASSIGNED USER PERMISSIONS'} color={'#000'}/>
                    <DebugTitleObj obj={activePermissions} title={'STATE: Assigned User Permissions'} color={'#e13'}/>
                    <DebugTitleObj obj={intersections.teams} title={'Shared Teams'} color={'#c0c'}/>
                </>
            }
            <Formik
                initialValues={inviteFormData}
                validationSchema={schema}
                onSubmit={()=>submitInvitation()}
                enableReinitialize={true}
            >
            {({ errors, touched}) => (
            <Form>

            <Alert
                visible={iDontHaveDepartments}
                status={'info'}
                headline={'No Organizations Assigned'}
                message={'You\'re not currently assigned to any organizations or departments'}
            />

            {//Invitation prompt and gretting button for invites
                !isEmpty(organizations) && interfaceInvite &&
                <PaperMargin small={true} sx={mgBtm}>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant={'body1'}>
                                {
                                    userSet ? 'Invite this user to ' : 'Create a group invitation for'
                                } your organization, departments, and teams.

                            </Typography>
                        </Grid>
                        {userSet === false && //group invites
                            <>
                                <Grid item xs={12}>
                                    <DebounceTextField
                                        fullWidth
                                        delay={250}
                                        size={'large'}
                                        label={'Invitation Title'}
                                        value={inviteFormData.title}
                                        onChange={(value)=>handleInviteData('title', value)}
                                        error={errors.title && touched.title}
                                        helperText={errors.title || ' '}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={'Maximum Allowed Invitees'}
                                        value={inviteFormData.maxLimit}
                                        onChange={(e)=>handleInviteData('maxLimit', e.target.value)}
                                        error={errors.maxLimit && touched.maxLimit}
                                        helperText={errors.maxLimit || ' '}
                                    />
                                </Grid>
                            </>
                        }
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                value={inviteFormData.invitationMessage}
                                onChange={(e)=>handleInviteNote(e.target.value)}
                                label={'Invitation Message/Greeting'}
                                placeholder={'Include a personalized message with your invitation here.'}
                                error={errors.invitationMessage && touched.invitationMessage}
                                helperText={errors.invitationMessage || ' '}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                select
                                label={'Invitation Expiration'}
                                value={inviteFormData.expiration}
                                onChange={(e)=>handleInviteData('expiration', e.target.value)}
                                helperText={`Invitation will expire past: ${inviteFormData.expirationString}`}
                            >
                                {expirationOptions.map((e, i)=>{
                                    return (
                                        <MenuItem value={e.value} key={i}>{e.label}</MenuItem>
                                    )
                                })}
                            </TextField>
                        </Grid>
                    </Grid>
                </PaperMargin>
            }

            { //Organization permissions exist: Display organization entries
                !isEmpty(organizations) && organizations.map(o=>{
                    const {organizationID, organizationTypeID, invitesEnabled} = o;
                    const organizationDisabled = o.disabled || !invitesEnabled;
                    const departmentEntries = o.deptEntries; //getOrgDepartmentEntries(organizationID);
                    const organizationLimited = !checkInviteOrganizationID(organizationID);
                    const icon = invitesEnabled || interfacePermissions ? <Business sx={ICON_SX}/>: <Warning color={'warning'} sx={ICON_SX}/>;
                    return (
                        <PreferencesAccordion
                            key={`${organizationID}.${organizationTypeID}`}
                            summaryText={`${o.orgShortName}: ${o.organizationName}`}
                            icon={icon}
                            defaultExpanded={false}
                        >
                            {displayDebugData &&
                                <>
                                    Disabled: {o.disabled ? 1 : 0}
                                    <br/><DebugTitleObj obj={departmentEntries} color={'#003'} title={'PM: Dept Entries'}/>
                                    {/*
                                    <DebugTitleObj obj={activePermissions} color={'#00f'} title={'ACTIVE PERMISSIONS'}/>
                                    <DebugTitleObj obj={pendingServerInvites} color={'#f00'} title={'PENDING INVITATIONS'}/>
                                    <DebugTitleObj obj={existingPermissions} color={'#0c0'} title={'Existing Permissions'}/>
                                    */}
                                </>

                            }



                            <Alert
                                visible={!iDontHaveDepartments && interfacePermissions && organizationDisabled}
                                status={'info'}
                                headline={'Permissions Disabled'}
                                message={'You do not currently have the authority to modify these permissions'}
                                style={mgBtm}
                            />
                            <Alert
                                visible={interfaceInvite && organizationDisabled}
                                status={'warning'}
                                headline={'Unverified Organization Disabled'}
                                message={'For security purposes personal invitations are not permitted for unverified organizations at this time. Try using a group invitation instead.'}
                                style={mgBtm}
                            />
                            <Alert
                                visible={organizationLimited && !organizationDisabled}
                                status={'warning'}
                                headline={'Organization Disabled'}
                                message={'Invitations are limited to one organization'}
                                style={mgBtm}
                            />
                            {
                                departmentEntries.map(d=>{
                                    const teams = d.teams != null ? d.teams : [];
                                    const {sectionID, organizationID, orgShortName, label, deptID, withinScope} = d;
                                    const groupLabel = `${orgShortName} ${label}`;

                                    //Permission Stuff
                                    const teamList = user.getTeamListByIDs(teams);
                                    const permissionAssignment = getDept(sectionID, 'active');
                                    const myDept = getMyDept(sectionID);
                                    const myDeptRank = parseInt(myDept.managerLevel);
                                    const outRanked = interfacePermissions && parseInt(permissionAssignment.managerLevel) > myDeptRank;
                                    const overridden = !interfaceInvite && permissionAssignment.override != null && parseInt(permissionAssignment.override) === 1;
                                    const permissionTeamRequired = permissionAssignment.interfacePermissionPending && permissionAssignment.teams.length === 0;
                                    //Real teams within dept overrides
                                    const realTeams = permissionAssignment.dbTeams ? permissionAssignment.dbTeams : null;
                                    const hasRealTeams = overridden && realTeams;

                                    //Invitation Interface
                                    const existingInviteAssignment = interfaceInvite ? getDept(sectionID, 'existing') : {managerLevel: null};
                                    const deptInvitePending = checkPendingInvitations(sectionID);
                                    const inviteDeptActive = interfaceInvite && permissionAssignment.managerLevel != null;
                                    const existingDeptActive = interfaceInvite && existingInviteAssignment.managerLevel != null;
                                    const inviteDeptActiveNoTeams = inviteDeptActive && permissionAssignment.teams.length === 0;
                                    const orgConflictLocked = interfaceInvite && !checkInviteOrganizationID(organizationID);
                                    const soloExistingPermission = existingDeptActive && !inviteDeptActive;

                                    //Team Requirement
                                    const teamRequired = permissionTeamRequired || inviteDeptActiveNoTeams;
                                    const realTeamColor = interfaceSelf ? 'secondary' : 'primary';

                                    //Setting colors for checkboxes
                                    const overrideColorStatus = 'warning';
                                    const teamRequiredColorStatus = 'error';
                                    let checkboxColor;
                                    if(hasRealTeams){
                                        checkboxColor = realTeamColor;
                                    } else if(overridden){
                                        checkboxColor = overrideColorStatus;
                                    } else if(interfaceSelf){
                                        //overridden by another dept
                                        checkboxColor = 'secondary';
                                    } else if(teamRequired){
                                        checkboxColor = teamRequiredColorStatus;
                                    } else {
                                        checkboxColor = 'primary';
                                    }

                                    let disabledReason;
                                    if(organizationDisabled && withinScope){
                                        disabledReason = 'Organization Disabled';
                                    } else if(soloExistingPermission){
                                        disabledReason = `${groupLabel} Already Assigned`;
                                    } else if(orgConflictLocked){
                                        disabledReason = 'Invitations limited to single organization';
                                    } else if(deptInvitePending){
                                        disabledReason = 'Active invitation pending';
                                    } else{
                                        disabledReason = 'Disabled';
                                    }

                                    const deptChecked = interfaceSelf || deptInvitePending || (!isNaN(parseInt(permissionAssignment.managerLevel))) || (interfaceInvite && (inviteDeptActive || existingDeptActive))
                                    //deptDisabled for both Depts and teams
                                    const deptDisabled = organizationDisabled || orgConflictLocked || deptInvitePending || outRanked || !withinScope;
                                    //deptEntryDisable is solely for < DeptEntry >
                                    const deptEntryDisabled = deptDisabled || soloExistingPermission;
                                    let setPermission = permissionAssignment;
                                    if(soloExistingPermission){
                                        setPermission = existingInviteAssignment;
                                    }
                                    return (
                                        <DeptEntry
                                            key={sectionID}
                                            sectionID={sectionID}
                                            deptID={deptID}
                                            interfaceType={interfaceType}
                                            orgShortName={orgShortName}
                                            label={label}
                                            permissionAssignment={setPermission}
                                            myDeptRank={myDeptRank}
                                            deviceAccount={deviceAccount}
                                            overridden={overridden}
                                            color={checkboxColor}
                                            checked={deptChecked}
                                            disabled={deptEntryDisabled}
                                            disabledReason={disabledReason}
                                            toggleFn={(type, deptSectionID, teamSectionID, on)=>toggleSection(type, deptSectionID, teamSectionID, on)}
                                            rankFn={(deptSectionID, level)=>editRank(deptSectionID, level)}
                                        >
                                            <Alert
                                                visible={teamRequired}
                                                status={teamRequiredColorStatus}
                                                headline={'Team Required'}
                                                message={'At least one team must be assigned.'}
                                                style={mgTopSmall}
                                            />
                                            <FormGroup>
                                            {teamList.map(t =>{
                                                const teamSectionID = t.sectionID;
                                                const team = user.getPermission(PERM_TEAM, teamSectionID);
                                                const teamName = `${team.orgShortName} ${team.label}`;
                                                const teamInvitePending = checkPendingInvitations(sectionID, teamSectionID);
                                                const {checked, inviteTeamExists} = getTeamChecked(sectionID, teamSectionID, permissionAssignment, existingInviteAssignment);
                                                let teamOverridden = overridden;

                                                if(hasRealTeams){
                                                    const realTeamExists = realTeams.includes(teamSectionID)
                                                    checkboxColor = realTeamExists ? realTeamColor : overrideColorStatus;
                                                    teamOverridden = !realTeamExists;
                                                }
                                                const display = checkDisplayTeam(sectionID, teamSectionID);


                                                if(false) {
                                                    console.log('-----------------------');
                                                    console.log(interfaceType, `TEAMCHECKED: ${o.orgShortName} ${d.label}: ${team.label}`, checked, '|||', 'SECTION ID', sectionID, 'TEAM', teamSectionID);
                                                    console.log('ACTIVE', permissionAssignment);
                                                    console.log('INVITE', existingInviteAssignment);
                                                    console.log('*');
                                                }
                                                const existingTeamAssignment = checked && inviteTeamExists;
                                                const teamDisabled = deptDisabled || !deptChecked || existingTeamAssignment || teamInvitePending || (interfaceSelf && isNaN(parseInt(permissionAssignment.managerLevel)))
                                                if(!display || (outRanked && !checked)){
                                                    return null;
                                                }
                                                return (
                                                    <TeamEntry
                                                        key={t.sectionID}
                                                        teamName={teamName}
                                                        teamSectionID={t.sectionID}
                                                        teamOverridden={teamOverridden}
                                                        organizationDisabled={organizationDisabled}
                                                        deptDisabled={deptDisabled}
                                                        invitePending={teamInvitePending}
                                                        deptInvitePending={deptInvitePending}
                                                        checked={checked}
                                                        toggleFn={()=>toggleSection(PERM_TEAM, sectionID, teamSectionID, !checked)}
                                                        color={checkboxColor}
                                                        disabled={teamDisabled}
                                                        interfaceSelf={interfaceSelf}
                                                    />
                                                )


                                            })}
                                            </FormGroup>
                                        </DeptEntry>
                                    )
                                })
                            }
                        </PreferencesAccordion>
                    )
                })
            }

            <Collapse in={schoolAthlete}>
                {
                    athleteAffiliations.map((affData, k) =>{
                        //const disabled = parseInt(affData.active)!==1;
                        return (
                            <PaperMargin small={true} key={affData.organizationID}>
                                <Typography variant={'h4'}>
                                    {affData.name} Affiliation & Eligibility
                                </Typography>
                                <Divider/>
                                <AthleteEligibilityForm
                                    newUser={true}
                                    disabled={schoolAthlete === false}
                                    affiliationKey={k}
                                    affiliationData={affData}
                                    updateFunction={(arrayKey, affiliationData)=>handleAthleteAffiliation(arrayKey, affiliationData)}
                                />
                            </PaperMargin>
                        )
                    })
                }
            </Collapse>

            {//Submission button for invites
                !isEmpty(organizations) && interfaceInvite &&
                <div>
                    <Alert
                        status={'error'}
                        visible={(!inviteValid && !isEmpty(activePermissions)) || !isEmpty(errors)}
                        style={mgTop}
                        headline={'Errors Exist'}
                        message={'Please fix your errors with your invitation before sending'}
                    />
                    <Button
                        fullWidth
                        color={'primary'}
                        variant={'contained'}
                        sx={mgTop}
                        disabled={!inviteValid}
                        type={'submit'}
                    ><CheckCircle/>{userSet ? 'Invite User' : 'Create Invitation'}</Button>
                </div>

            }


            </Form>
            )}
            </Formik>


            <>

            <Modal
                open={displayModal}
                title={'Confirm Removal'}
                modalActions={
                    <>
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            disabled={modalButtonDisabled}
                            onClick={()=> {
                                setLastTeamWarning('submit'); //team swap submission
                                submitPendingChanges(); //send any pending permission submissions
                                setDisplayModal(false); //close the modal
                            }}
                        ><CheckCircle/> {modalButtonText}</Button>
                        <CloseButton onClick={()=>{
                            setDisplayModal(false);
                            setLastTeamWarning('close');
                            setPendingSubmissionData({});
                        }}/>
                    </>
                }
            >


                {
                    modalSection === removingSelfWarning &&
                    <div>
                        <Alert
                            visible={true}
                            status={'warning'}
                            headline={'CAUTION: This Can Not Be Undone'}
                            message={'Modifying your permissions are irreversible.'}
                        />
                        <Typography variant={'body1'}>
                            <br/>
                            If you're removing yourself from an organization's department, you will eliminate all access to teams assigned under that department.
                            If you're removing yourself from a team, you will no longer have access to any team data including:
                        </Typography>
                        <ul>
                            <li>Roster Access</li>
                            <li>Team Statistics</li>
                            <li>Workouts</li>
                        </ul>
                        <Typography variant={'body1'}>
                            This action is irreversible. You will need to contact a superior to regain access.
                        </Typography>
                        {RadioWarningGroup}
                    </div>
                }

                {
                    modalSection === removingDeptWarning &&
                    <div>
                        <Alert
                            visible={true}
                            status={'warning'}
                            headline={'CAUTION: Eliminating Department And All Visible Teams'}
                            message={'Removing the department will also remove all associated teams.'}
                        />
                        <Typography variant={'body1'}>
                            <br/>
                            Deactivating this department will removal all visible teams underneath it. Are you sure you want to do this?
                        </Typography>
                        {RadioWarningGroup}
                    </div>
                }

                {
                    modalSection === lastTeamWarning &&
                    <div>
                        <Alert
                            status={'warning'}
                            visible={true}
                            headline={'CAUTION: Removing Last Team'}
                            message={'Removing the user\'s last team could also remove the department'}
                        />
                        <Typography variant={'body1'} sx={mgTop}>
                            Deactivating the user's last team could remove them from the department. If you want to swap teams,
                            use the selector below to replace the user's team. Alternatively, you can proceed with removing the user's last team
                            which will likely remove them from the department.
                        </Typography>
                        <Grid container spacing={2} sx={mgTopSmall}>
                            <Grid item xs={12}>
                                <RadioGroup value={teamSwapData.swapOption}>
                                    <FormLabel>How do you want to proceed?</FormLabel>
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                value={1}
                                                color={'primary'}
                                                onChange={(e)=>handleSwapData('swapOption', parseInt(e.target.value))}
                                            />
                                        }
                                        label={'I want to swap the user\'s team'}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                value={0}
                                                color={'secondary'}
                                                onChange={(e)=>handleSwapData('swapOption', parseInt(e.target.value))}
                                            />
                                        }
                                        label={'I want to remove their last team'}
                                    />
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    select
                                    disabled={teamSwapData.swapOption === 0}
                                    value={teamSwapData.swapValue}
                                    onChange={(e)=>handleSwapData('swapValue', e.target.value)}
                                    label={'Select Replacement Team'}
                                >
                                    {
                                        teamSwapData.teamOptions.map(t =>{
                                            const value = parseInt(t.sectionID);
                                            const disabled = value === teamSwapData.deactivatedTeamID;
                                            const teamName =`${t.orgShortName} ${t.label}`;
                                            const nodeValue = disabled ? `${teamName} (Deactivating)` : teamName;
                                            return (
                                                <MenuItem
                                                    value={value}
                                                    disabled={disabled}
                                                >
                                                    {nodeValue}
                                                </MenuItem>
                                            )
                                        })

                                    }
                                </TextField>
                            </Grid>
                        </Grid>
                    </div>
                }

                {
                    modalSection === removingTeamWarning &&
                    <div>
                        <Alert
                            visible={true}
                            status={'warning'}
                            headline={'CAUTION: This Can Not Be UnDone'}
                            message={'You will need to invite the user back.'}
                        />
                        <Typography variant={'body1'}>
                            <br/>
                            You will need to invite the user back to the team if you remove them. Is this something you want to do?
                            <br/>
                        </Typography>
                        {RadioWarningGroup}

                    </div>
                }
            </Modal>
            </>
        </>
    )
}

const mapStateToProps=(state)=> {
    return {FITUser: state.FITUser};
}
export default connect(mapStateToProps)(PermissionsManager);


