import User from './User';
import {enqueueSnackbar} from 'notistack';
import {isEmpty, getStatusColor, wait} from "./UtilityFunctions";
import {API_ENDPOINT, SESSION_TIME_STRING, SNACKBAR_DEFAULT_PROPS, PERM_SET, PERM_DEPT, PERM_TEAM, PRODUCTION_STATUS} from "./FITConstants";
import PaperMargin from "../components/PaperMargin";
import React from "react";
/*
	-PROTOTYPE FOR MAKING SECURE CONNECTIONS TO THE SERVER
	-Receive url, method, formAction, outputNotifications, outputSuccessMessages
		-OutputNotifications: true/false (hide all messages - default should be true)
		-OutputSuccessMessages: true/false (allow for hiding of success messages - e.g. displaying tables)
	-Receive response from server
		-0. Check permission is allowed. If denied. Shut the whole thing down
		-1. Check any userdata provided. If so, update user interface
		-2. Display notifications depending on notifications setup
		-3. Output data to component/state as necessary
*/
const serverFailedMessage = '#RJS0N-E01: Please Contact Carol In HR.';



class ConnectionCache{
	/*
	-Handling Multiple, Connections to and from the server
	-Intercept duplicate requests within time period
	-Throttle/slow connections when user is updating
	*/
	constructor(cacheExpiration) {
		//How many seconds to keep the data in the cache
		this.cacheExpiration = cacheExpiration; //milliseconds
		//How long of an interval before retrying a connection when updating user details
		//This is to synchronize tokens between the client and the server
		this.cacheLocationString = '__connectionCache';
		this.updateUserStatusString = 'updatingUser';
		//List of user actions that shouldn't be cached for security purposes
		this.avoidedActions = ['login','logout'];
		this.request = {
			method: '',
			url: '',
			parameters: ''
		}
	}
	setRequest(method, url, parameters){
		this.request = {method, url, parameters};
	}
	#checkRequest(){
		const {method, url, parameters} = this.request;
		const errorStyle = 'font-size: 14px; color: red; font-weight: bold; background: #ffcccc;';
		let valid = true;
		if(method === ''){
			console.warn('%cREQUEST METHOD MISSING', errorStyle);
			valid = false;
		}
		if(url === ''){
			console.warn('%cREQUEST URL MISSING', errorStyle);
			valid = false;
		}
		if(method.trim().toLowerCase() === 'post' && parameters === ''){
			console.warn('%cREQUEST PARAMETERS MISSING', errorStyle);
			valid = false;
		}
		return valid;
	}
	checkCache(){
		const log = false;
		const checkPost = false;
		const valid = this.#checkRequest();
		if(!valid){
			return;
		}
		const cache = this.#getCache();
		const deletionTime = this.#getTimestamp() - this.cacheExpiration;
		const {method, url, parameters} = this.request;
		const stringifiedParams = `"${parameters}"`; //hacky shit. Double quotes get wrapped around params when creating the object
		const result = cache.find(c => c.timeStamp > deletionTime && c.method === method && c.url === url && (c.pending === true || parameters.length === 0 || c.parameters === stringifiedParams));

		if(checkPost && this.request.method === 'post'){
			console.log('REQUEST', this.request);
			cache.forEach(c => {
				if(c.method === method) {
					console.log(c);
					console.log('TIME VALID', c.timeStamp > deletionTime, 'TS=>', c.timeStamp, 'DT=>', deletionTime, '(',c.timeStamp - deletionTime,')');
					console.log('__PARAM MATCH', c.parameters === stringifiedParams);
					console.log('CURRENT PARAMS', parameters);
					console.log('CACHE PARAMS', c.parameters);

				}
			});
		}

		if(log) {
			console.log('METHOD =', method);
			console.log('URL =', url);
			console.log('PARAMS = ', parameters, parameters.length);
			console.log('CHECKING CACHE', cache);
			console.log('CACHE RESULT', result);
		}
		return result;
	}
	setPending(){
		const valid = this.#checkRequest();
		if(!valid){
			return;
		}
		let cache = this.#getCache();
		cache.push(this.#createRecord(this.#getTimestamp(), {}, true));
		this.#setCache(cache);
	}
	updateCache(response){
		//Remove old responses based on timestamp and delayMS
		const now = this.#getTimestamp();
		let cached = this.#getCache();
		let updatedCache = [];
		//Update Cache with recent/fresh/current responses
		const {method, url, parameters} = this.request;
		for(let i = 0; i < cached.length; i++){
			const c = cached[i];
			//Within time range of cache
			const fresh = c.expiry > now;
			//Different request than the one being updated
			//This will eliminate pending cache entries
			const different = c.method !== method && c.url !== url && c.parameters !== parameters;
			if(fresh && different){
				updatedCache.push(c);
			}
		}
		//update pending with current
		updatedCache.push(this.#createRecord(now, response));
		this.#setCache(updatedCache);
	}
	async waitForData(){
		const valid = this.#checkRequest();
		if(!valid){
			return;
		}
		const refreshInterval = 150; //ms
		return await new Promise(resolve =>{
			const interval = setInterval(()=>{
				const cached = this.checkCache();
				//console.log(`%cWAITING ${this.request.method}: ${this.request.url}`, 'color: #0ff;');
				if(cached) {
					const {pending} = cached;
					if (!pending) {
						clearInterval(interval);
						return cached.response;
					}
				} else{
					//NOT FOUND. DIE;
					clearInterval(interval);
				}
			}, refreshInterval);
		}); //end promise

	}
	#createRecord(timeStamp, response, pending = false){
		const {method, url, parameters} = this.request;
		return {
			timeStamp: timeStamp,
			expiry: parseInt(timeStamp + this.cacheExpiration),
			pending,
			method,
			url,
			parameters: JSON.stringify(parameters),
			response,
		}
	}
	#getTimestamp(){
		return Math.floor(Date.now());
	}
	#getCache(){
		const cacheString = localStorage.getItem(this.cacheLocationString);
		return cacheString && cacheString !== '' ? JSON.parse(cacheString) : [];
	}
	#setCache(cache){
		localStorage.setItem(this.cacheLocationString, JSON.stringify(cache));
	}
}


export class SecureConnect {
	constructor(endpoint, method = 'get'){
		const user = new User();
		this.url = API_ENDPOINT;
		this.endpoint = endpoint;
		this.method = method.trim().toLowerCase();
		this.displayNotifications = true;
		this.displaySuccessMessages = true;
		this.receiveFile = false;
		this.logConsole = false;
		this.action = '';
		this.headers = {
			//'WWW-Authenticate': user.getAPIKey(),
			//Authorization: 'Bearer ' + user.getAPIKey()
		};
		this.denyAccessCode = 401; //Response code that will automatically log a user out
		this.formData = {};
		this.tokenKey = user.CSRF_TOKEN;
		this.updateSessionTime = true;
		this.userUpdatingString = '_userUpdating';
		this.cacheExpiration = 2500;
		this.refreshTimeThrottle = 1000;
		this.updateClientDelayMS = 200;
		this.useNewState = true;
		//console.log('created connection class');console.log(url);
	}
	setLogConsole(bool){
		this.logConsole = bool;
	}
	setAction(action){
		//Used for Posting to an endpoint
		this.action = action;
	}
	setDisplayNotifications(bool){
		//Determines whether notifications should be displayed
		this.displayNotifications = Boolean(bool);
	}
	setDisplaySuccessMessages(bool){
		//Determines whether success notifications should be displayed
		this.displaySuccessMessages = Boolean(bool);
		if(bool) {
			//Automatically display notifications because success messages are getting displayed
			this.displayNotifications = Boolean(bool);
		}
	}
	setCacheExpiration(ms){
		this.cacheExpiration = parseInt(ms);
	}
	setUpdateSessionTime(bool){
		this.updateSessionTime = Boolean(bool);
	}
	setFormData(formData){
		this.formData = formData;
	}
	setReceiveFile(bool){
		this.receiveFile = Boolean(bool);
	}
	getParameters(){
		let user = new User();
		const token = user.getCSRFToken();
		let body, parameterString = '';
		if(Object.keys(this.formData).length > 0){
			this.formData['action'] = this.action;
			parameterString = Object.keys(this.formData).map(e => `&${e}=${this.formData[e]}`).join('');
			this.formData[this.tokenKey] = token;
			body = new URLSearchParams(this.formData);
			if(this.method === 'post') {
				this.headers = {...this.headers, 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'};
			}
		}
		return {body, parameters: parameterString};
	}
	async delay(delay = null){
		const dwellTime = delay!= null ? delay : this.updateClientDelayMS;
		await wait(dwellTime);
		return true;
	}
	async pauseForUserUpdate() {
		let updating = this.#getUpdatingUser();
		if(!updating){
			return true;
		}
		while(updating){
			console.log('----DELAYING...UPDATING');
			console.log('----DELAYING...UPDATING');
			console.log('----DELAYING...UPDATING');
			console.log('----DELAYING...UPDATING');
			await this.delay();
			updating = this.#getUpdatingUser();
		}
		return true;
	}
	async connect() {
		//console.log('CONNECTING');
		await this.delay(10);
		//Pause so the user can update with any new tokens
		await this.pauseForUserUpdate();
		const user = new User();
		let {body, parameters} = this.getParameters();
		let url = this.getEndPoint();
		//Initialize Cache
		const cc = new ConnectionCache(this.cacheExpiration);
		cc.setRequest(this.method, url, parameters);
		//Handle Caching of previous/duplicate requests
		const cached = cc.checkCache();
		if(cached){
			//Determine pending status
			const {pending} = cached;
			if(pending){
				//console.log('%cWAITING PENDING', 'color: #0FF');
				return await cc.waitForData();
			} else {
				//If Pending. Set Interval and wait for response
				//if already exists, return data;
				//console.log(`%cCACHED ${this.method}: ${url}`, 'color: #00cd1d;');
				//console.log(cached.response);
				return cached.response;
			}
		}

		//Connection not cached. Set Pending
		cc.setPending();
		this.setSessionTimeStamp(1, false);

		if(this.method === 'get' && body != null){
			//When parameters are passed via formData rather than url string
			url = `${url}?${body}`;
			//No need to duplicate body in payload.
			body = null;
		}

		//Add in token for _GET requests
		if(this.method === 'get' && url.includes(`&${this.tokenKey}=`) === false){
			url = `${url}&${this.tokenKey}=${user.getCSRFToken()}`
		}

		const payload = {
			body: body,
			method: this.method,
			headers: this.headers,
			credentials: 'include', //PRODUCTION_STATUS ? 'same-origin' : 'include',
		}
		let data;
		try {
			if(this.receiveFile === false) {
				data = await (await fetch(url, payload))
					.json()
					.then(data => this.manageUserData(data))
					.then(data => {
						//Update Client side connection cache
						cc.updateCache(data);
						//Handle Snackbar for the server response
						this.displaySnackbar(data);
						//Clear connecting status
						this.setSessionTimeStamp(0, true);
						//Turn the user status back on
						if(!user.getOnlineStatus()){
							user.setOnlineStatus(true);
						}


						//Return back to Component/Calling interface
						return data;
					});
				//Set the sessionTimeStamp for handling idling of the user
				return data;
			} else{
				//RECEIVING THE FILE
				fetch(url, payload).then(res => res.blob())
					.then(blob =>{
						console.log(blob)
					})
			}
		} catch (e) {
			if(true || this.logConsole) {
				console.error('-----------------ERROR-----------------');
				console.error('%c' + e, 'font-size: 14px; color:red; font-weight: bold;');
				console.log(url);
				console.log(payload);
				console.error('-----------------ERROR-----------------');
			}
			if(url.includes('system.php?action=authUser') === false){
				this.createSnackbarNotification(4, 'Server Connection Error', serverFailedMessage);
			}


			//increment the failed connection attempts
			let state = user.getUserConnectionState();
			let updatedAttempts = parseInt(state.failedAttempts)+1;
			console.log('UPDATING FAILED ATTEMPTS', updatedAttempts);
			user.setUserConnectionState({failedAttempts: updatedAttempts});
			if(updatedAttempts >= 3){
				user.setOnlineStatus(false);
			}

			return this.clientSideError();
		}
	}
	displaySnackbar(json, cached = false) {
		const response = json.response;
		const outputCodeLists = [2, 3, 4, 401];
		const serverResponseCode = parseInt(response.code);
		//0. If logged out/session terminated: set login form as interface
		const consoleList = [
			{style: 'font-size: 14px; color: red; font-weight: bold; background: #ffcccc;'}, //Error
			{style: 'font-size: 14px; color: white; font-weight: normal; background: #00CD1D;'}, //Success
			{style: 'font-size: 14px; color: white; font-weight: bold; background: #00CCFF;'}, //INFO
			{style: 'font-size: 14px; color: #FF5500; font-weight: bold; background: #FF0;'}, //WARNING
			{style: 'font-size: 14px; color: red; font-weight: bold; background: #ffcccc;'}, //ERROR
		];
		if (this.logConsole) {
			console.log('---------------SecureConnect.js.managerServerResponse()------------------');
			console.log(response);
			console.log('--------------/SecureConnect.js.managerServerResponse()------------------');
		}



		//Handle responses & errors
		//1. IF error connecting to server, display error (regardless of displayNotifications)
		//2. Based on code determine which kind of message to display
		//-Code 401 = Unauthorized Access Handle Separately
		//If. Code = 1 = success message. Display message
		if(!cached && this.displayNotifications && (
				(this.displaySuccessMessages && serverResponseCode === 1) ||
				(serverResponseCode !== 1 && outputCodeLists.filter(code => (code === serverResponseCode)))
			)){
				//const style = consoleList[serverResponseCode].style;
				this.createSnackbarNotification(serverResponseCode, response.codeText, response.msg);
		}
	}
	async manageUserData(json){
		const user = new User(this.logConsole);
		if(this.getResponseCode(json) === this.denyAccessCode){
			//Access Denied. Log the user out.
			user.setClientStorePermissions(false, {}); //Log the user out
			user.initUserConnectionState();
			return;
		}
		if(json.userData){ //userData provided - update permissions
			const authorized = json.userData[PERM_SET] != null && !isEmpty(json.userData[PERM_SET]);
			console.log('NEW USER DATA FOUND', json.userData);
			this.#setUpdatingUser(1);
			if(this.logConsole) {
				console.log('=================SecureConnect.js=====================');
				console.log('SC AUTHORIZATION', authorized, json);
				console.info('USER DATA FOUND');
				console.log(json.userData);
				console.log('Setting User.JS clientStorePermissions()');
				console.log('================/SecureConnect.js=====================');
			}

			//Update permissions
			const systemPermissions = {
				[PERM_DEPT]: this.compilePermissions(json.userData[PERM_SET][PERM_DEPT]),
				[PERM_TEAM]: this.compilePermissions(json.userData[PERM_SET][PERM_TEAM])
			}
			console.log('SYS PERMISSIONS');
			console.log(systemPermissions);
			json.userData[PERM_SET] = systemPermissions;


			//update user data in localStorage & state
			user.setClientStorePermissions(authorized, json.userData);
			delete json.userData;
			//update with measurement data if provided
			if(authorized) {
				const sc = new SecureConnect('');
				user.setMeasurementData(sc.getData(json));
			}
			await this.delay();
			this.#setUpdatingUser(0);

		}
		return json;
	}
	compilePermissions(permissionSet){
		let set = [];
		Object.keys(permissionSet).forEach(k =>{
			const permission = permissionSet[k];
			set.push(permission);
		})
		return set;
	}
	setSessionTimeStamp(connecting = 1, resetFailedAttempts = false){
		if(this.updateSessionTime) {
			const user = new User();
			let obj = {connecting, [SESSION_TIME_STRING]: Date.now()};
			if(resetFailedAttempts){
				obj.failedAttempts = 0;
			}
			user.setUserConnectionState(obj);
		}
	}
	getURL(){
		return this.url;
	}
	getEndPoint(){
		return `${this.url}${this.endpoint}`;
	}
	getCompleted(json){
		//return bool if action was completed
		if(this.logConsole) {
			console.log(json);
		}
		return this.checkResponse(json) && json.response.completed === 1;
	}
	clientSideError(){
		//return a generic error if client cannot contact the server
		//console.log('SECURECONNECT.JS :: RETURNING DEFAULT ERROR');
		return {
			completed: 0,
			code: 0,
			codeText: 'Error',
			msg: '#CSJS4506 Unable to contact server.',
			msgTypeClass: 'alert-error',
			rowsAffected: 0
		};
	}
	createSnackbarNotification(code, codeText, message){
		const codeAssignments = {
			0: 'warning',
			1: 'success',
			2: 'info',
			3: 'warning',
			4: 'error',
			401: 'error'
		};

		const color = getStatusColor(codeAssignments[code]);

		//Assign color parameter to the SnackBar
		const variant = codeAssignments[code] != null ? codeAssignments[code] : 'default';
		const props = {
			...SNACKBAR_DEFAULT_PROPS,
			variant,
			style:{background: color}
		}
		enqueueSnackbar(message, props);
	}
	getResponse(json){
		//Return the response section of the server JSON response
		if(this.checkResponse(json)) {
			return json.response;
		} else{
			console.error('Connection: invalid response. Sorry. Blame the malformed response.');
			return this.clientSideError();
		}
	}
	getData(json){
		//Return the data section of the server JSON response
		if('data' in json) {
			return json.data.dataSet;
		} else{
			//Nothing to return empty set (nothing)
			return {}; //null;
		}
	}
	getTableStructure(json){
		if('data' in json){
			return json.data.tableStructure;
		} else{
			return {};
		}
	}
	getResponseCode(json){
		//Return responseCode or errorCode (4)
		return this.checkResponse(json) ? parseInt(json.response.code) : 4;
	}
	getResponseMessage(json){
		return json.response != null && json.response.msg != null ? json.response.msg : serverFailedMessage;
	}
	checkResponse(json){
		return json != null && json.response != null && json.response.code != null && json.response.completed != null;
	}
	#setUpdatingUser(integer){
		const time = integer === 1 ? Date.now() : 0
		const data = {status: integer, timestamp: time};
		if(this.useNewState){
			const user = new User();
			user.setUserConnectionState({updating: integer, updateTimestamp: time});
		}

		//localStorage.setItem(this.userUpdatingString, JSON.stringify(data));
	}
	#getUpdatingUser(){

		if(this.useNewState){
			const user = new User();
			const connectionState = user.getUserConnectionState();
			const {updating, updateTimestamp, nextUpdate, connecting} = connectionState;
			const margin = Math.floor(this.updateClientDelayMS/2);
			const updateImminent = false; //parseInt(connecting) === 1 && Date.now()-margin > nextUpdate;
			const currentlyUpdating = updating != null && parseInt(updating) === 1 && Date.now() < updateTimestamp+this.updateClientDelayMS;
			if(updateImminent || currentlyUpdating) {
				console.log(this.endpoint, 'cnct?',parseInt(connecting) ===1 , 'UPDATING?', updating, updateTimestamp, '=>', currentlyUpdating, 'UPDATE IMMINENT?', updateImminent);
			}
			return updateImminent || currentlyUpdating;
		}

		/*
		const json = localStorage.getItem(this.userUpdatingString);
		const log = false;
		if(log) {
			console.log('GETTING UPDATING STATUS');
		}
		if(json?.length){
			const {status, timestamp} = JSON.parse(json);
			if(log){
				console.log('UPDATING?', status && parseInt(status) === 1 && Date.now() < timestamp+this.updateClientDelayMS, 'STATUS/TIME', status, timestamp, );
			}
			//updating user is only valid under the following conditions:
			//status = 1
			//current timestamp exceeds when the updating user was set
			//set a limit on the updating of the user
			return status && parseInt(status) === 1 && Date.now() < timestamp+this.updateClientDelayMS;
		}
		return false;
		 */
	}
}

export default SecureConnect;