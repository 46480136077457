import React from 'react';
import LegalPage from "./LegalPage";
import TermsOfService from "./TermsOfService";
const TermsPage=()=>{
    return (
        <LegalPage header={'Terms Of Service'}>
            <TermsOfService/>
        </LegalPage>
    )
}

export default TermsPage;