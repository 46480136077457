import React from 'react';
import {Typography, Grid, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, FormGroup} from "@mui/material";
import UserPreferences from "fit/system/UserPreferences";
import PreferencesAccordion from "../../PreferencesAccordion";
import {AccountCircle} from "@mui/icons-material";
import Alert from "../../Alert";
import {ICON_SX} from "fit/system/FITConstants";

const PrivacySettings=({headerVariant})=>{
    const variant = headerVariant ? headerVariant : 'h3';
    return (
        <>
            <PreferencesAccordion
                icon={<AccountCircle sx={ICON_SX}/>}
                summaryText={'Public Profile Settings'}
            >
                <RadioGroup>
                    <FormLabel>Public Profile Page</FormLabel>
                    <FormControlLabel
                        control={<Radio color={'primary'}/>}
                        label={'On: Allow users to find me through the public directory.'}
                    />
                    <FormControlLabel
                        control={<Radio color={'secondary'}/>}
                        label={'Off: Remove me from the public directory. Keep my profile private so nobody can find me.'}
                    />
                </RadioGroup>
                <Alert
                    status={'info'}
                    visible={true}
                    headline={'Your Profile Is Currently Private/Hidden'}
                    message={'Settings below can be modified, but will not matter as your profile is private.'}
                />
                <RadioGroup>
                    <FormLabel>Display My Name</FormLabel>
                    <FormControlLabel
                        control={<Radio color={'primary'}/>}
                        label={'Display both my first and last name'}
                    />
                    <FormControlLabel
                        control={<Radio color={'primary'}/>}
                        label={'Display my first initial and my last name'}
                    />
                    <FormControlLabel
                        control={<Radio color={'primary'}/>}
                        label={'Display my first name and the initial of my last name'}
                    />
                    <FormControlLabel
                        control={<Radio color={'primary'}/>}
                        label={'Display only the initials of my first and last name'}
                    />
                </RadioGroup>
                <RadioGroup>
                    <FormLabel>Profile Picture</FormLabel>
                    <FormControlLabel
                        control={<Radio color={'primary'}/>}
                        label={'On: Display my picture on my public profile.'}
                    />
                    <FormControlLabel
                        control={<Radio color={'secondary'}/>}
                        label={'Off: Remove my picture from my public profile.'}
                    />
                </RadioGroup>
                <RadioGroup>
                    <FormLabel>Allow Invitations from Unaffiliated Teams</FormLabel>
                    <FormControlLabel
                        control={<Radio color={'primary'}/>}
                        label={'On: Allow coaches to invite me to their teams.'}
                    />
                    <FormControlLabel
                        control={<Radio color={'secondary'}/>}
                        label={'Off: I only want to receive invitations from official organizations and institutions.'}
                    />
                </RadioGroup>
                <RadioGroup>
                    <FormLabel></FormLabel>
                </RadioGroup>



            </PreferencesAccordion>


            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ul>
                        <li>Necessary Note/Reminder: Your Coaches and Superiors will be still be able to find you through their organization's directories</li>
                        <li>Intro text summary something like: These settings protect your information from "outsiders" or people outside your hierarchical structure</li>
                        <li>Enable Public Profile<ul>
                            <li>Display Profile Picture to outsiders: 1/0</li>
                            <li>Display Personal Stats <em>(Allow people to choose which stats they show)</em></li>
                            <li>Maybe eventual videos/highlight reels for stats, athletic performances? (Mini scouting profile)</li>
                            <li>Education, History (similar to linked-in)<ul>
                                <li>This will be necessary for Coaches (both sport specific & strength and conditioning)</li>
                                <li>Display Certifications, education, background, masters..etc</li>
                            </ul></li>
                        </ul></li>
                        <li>User Directory Settings<ul>
                            <li>Global User Directory Setting: 1/0</li>
                            <li>Scouting/Athletic Performance Directory (for athletes): 1/0 (Is this the same thing as above? consolidate?)</li>
                            <li>How people can find you:<ul>
                                <li>Name</li>
                                <li>Phone</li>
                                <li>Email</li>
                                <li>Sports, KPI's, other pii, etc</li>
                            </ul></li>
                        </ul></li>
                        <li>Market Place settings:<ul>
                            <li>Figure out what data gets shared with coaches show sell workouts</li>
                            <li>Streamline or provide more interactivity between coaches and athletes</li>
                        </ul></li>
                    </ul>
                </Grid>
            </Grid>
        </>

    )

}

export default PrivacySettings;