import React from 'react';
import LegalPage from "./LegalPage";
import PrivacyPolicy from "./PrivacyPolicy";

const PrivacyPage=()=>{
    return (
        <LegalPage header={'Privacy Policy'}>
            <PrivacyPolicy/>
        </LegalPage>
    )
}

export default PrivacyPage;