import React from 'react';
import {FormControlLabel, Checkbox, Grid, TextField, MenuItem, InputAdornment, Tooltip} from "@mui/material";
import {getSpacing, getStatusColor, getStatusIcon} from "fit/system/UtilityFunctions";
import {
    ATHLETE_MANAGER_LABELS,
    DEPT_MINIMUM_REQUIRED_LEVELS, DEVICE_ACCOUNT_LABEL, DEVICE_MASTER_LEVEL,
    OPERATIONS_LEVEL,
    PERM_DEPT, PRODUCTION_STATUS
} from "fit/system/FITConstants";
import User from "fit/system/User";

const DeptEntry=({
    sectionID,
    deptID,
    orgShortName,
    label,
    interfaceType, //self, invite, permissions
    permissionAssignment, //activePermission assignment
    toggleFn, //toggle function
    rankFn, //rank modification function
    myDeptRank, //The rank of the viewer looking at the dept
    deviceAccount,
    disabled, //Disables form elements
    disabledReason = '', //ToolTip explanation for disabling form elements
    children,
    color, //color of the checkbox
    overridden, //The status ('error','warning'..etc) associated with overridden depts
    checked, //bool
    })=>{



    const initRank=()=>{
        /*
            -for interface invites
            -Editor may attempt to invite someone of a higher rank to their dept/teams
            -User's rank will be higher/outside the menu options for the editor
            -This will set the managerLevel to the default Rank for the dept
         */
        try{
            const managerRank = parseInt(permissionAssignment.managerLevel);
            return managerRank;
        } catch (e){
            console.warn('NO PERMISSIONS');
            return null;
        }
    }


    const interfaceSelf = interfaceType === 'self';
    const user = new User();
    const groupLabel =  `${orgShortName} ${label}`
    const deptLabel = PRODUCTION_STATUS ? groupLabel : `${groupLabel}: [${sectionID}]`;
    const inputLabel = `${label} Rank`;
    const hasRealTeams = overridden && permissionAssignment.dbTeams != null && permissionAssignment.dbTeams.length > 0;
    const overrideSystemDisabled = overridden && !hasRealTeams;
    const setManagerRank = initRank();
    const marginSpacing = {marginBottom: getSpacing('small')};

    const getAssignedTeamRankString=(managerLevel)=>{
        const none = 'Unspecified';
        if(!checked){
            return none;
        }
        managerLevel = parseInt(managerLevel);
        const list = ATHLETE_MANAGER_LABELS.find(item => parseInt(item.value) === managerLevel);
        return list != null && list.label != null ? list.label : none;
    }
    const getManagerRanges=()=>{
        const dept = DEPT_MINIMUM_REQUIRED_LEVELS.filter(dept => parseInt(dept.deptID) === parseInt(deptID));
        if(dept.length > 0){
            return {min: dept[0].minimum, max: dept[0].maximum}
        }
        return {min: null, max: null};
    }
    const limitMenuOptions=()=>{
        //LIMIT THE options on what the user/viewer can select based on compared permissions
        //Get the viewer's/user's permissions for the specific group
        const {min, max} = getManagerRanges();
        const myRank = parseInt(myDeptRank);
        //Only allow permissions that are equal to or less than the viewer's managerLevel
        let allowedPermissions = [];
        ATHLETE_MANAGER_LABELS.forEach(option => {
            const {preventSelection} = option;
            const optionValue = parseInt(option.value);
            const managerRankMatch = optionValue === setManagerRank;
            const minimumMet = min == null || optionValue >= parseInt(min);
            const withinMax = max == null || optionValue <= parseInt(max);
            //ORIGINAL CODE: LIMIT OPTIONS WITHOUT DISABLING (THIS WOULD HIDE OPTIONS)
            if(preventSelection === false && minimumMet && withinMax && (optionValue <= myRank || managerRankMatch)){
                //Disable options that are at the viewer's level - Cannot equalize or go above viewer's permissions
                const optionDisabled = optionValue === myRank;
                allowedPermissions.push({...option, disabled: optionDisabled});
            }
        });
        return allowedPermissions;
    }







    //Override InputAdornment for editor rank
    const settingOptions = ['success','info','warning','error'];
    const overrideSetting = overridden && settingOptions.includes(color) ? color : 'warning';
    const overrideColor = getStatusColor(overrideSetting);
    const overrideIcon = getStatusIcon(overrideSetting, {color: overrideColor});
    const overrideText = `Overridden By ${permissionAssignment.orgShortName} ${permissionAssignment.overrideDeptName} Department`;
    const toggleAction = overrideSystemDisabled ? ()=>console.warn('DEPARTMENT OVERRIDDEN: CANT EXECUTE') : () => toggleFn(PERM_DEPT, sectionID, null, !checked);
    const overrideProps = {
        endAdornment: <InputAdornment position={'end'}>
            <Tooltip title={overrideText} placement={'bottom'}>
                {overrideIcon}
            </Tooltip>
        </InputAdornment>
    }
    const rankProps = overridden ? overrideProps : {};



    //Disable items if the viewer isn't a sport specific coach and the entry is a team
    const operationsLevel = user.getManagerDepts(OPERATIONS_LEVEL).length > 0;
    const subOperations = operationsLevel === false; //interfaceSelf;
    const rankDisabled = subOperations || interfaceSelf;
    const disabledText = subOperations ? '(Disabled)':'';
    const verb = interfaceSelf ? 'Remove/Quit' : 'Toggle';
    let checkboxTip = !disabled ? `${verb} ${deptLabel} ${disabledText}` : disabledReason;
    if(overrideSystemDisabled){
        checkboxTip = overrideText;
    }

    const menuOptions = limitMenuOptions();

    return (
        <Grid container spacing={2} style={marginSpacing}>
            <Grid item xs={12} sm={5} style={{textAlign: 'right', marginTop: getSpacing('iconAlignment')}}>
                <FormControlLabel
                    label={deptLabel}
                    labelPlacement={'start'}
                    style={{position: "relative", bottom: '8px'}}
                    control={
                        <Tooltip
                            title={checkboxTip}
                            enterDelay={300}
                            placement={'right'}
                        >
                        <span>
                        { !disabled  ?
                            <Checkbox
                                checked={checked}
                                onChange={toggleAction}
                                name={'toggle'}
                                value={1}
                                color={color}
                            />
                            :
                            <Checkbox
                                checked={checked}
                                name={'toggle'}
                                color={'secondary'}
                                disabled={true}
                            />
                        }
                        </span>
                        </Tooltip>
                    }
                />
            </Grid>
            <Grid item xs={12} sm={7}>
                {checked && !overridden && !disabled?
                    <TextField
                        fullWidth={true}
                        size={'small'}
                        select={true}
                        label={inputLabel}
                        value={setManagerRank}
                        onChange={(e) => rankFn(sectionID, e.target.value)}
                        disabled={rankDisabled} // || disabled || checked === false

                    >
                        {
                            deviceAccount ?
                                <MenuItem value={DEVICE_MASTER_LEVEL}>{DEVICE_ACCOUNT_LABEL}</MenuItem>
                                :
                                menuOptions.map((item, k) => {
                                    const optionDisabled = disabled || item.disabled;
                                    return (
                                        <MenuItem key={k} value={item.value}
                                                  disabled={optionDisabled}>{item.label}</MenuItem>
                                    )
                                })
                        }
                    </TextField>
                    :
                    <TextField
                        fullWidth={true}
                        size={'small'}
                        label={inputLabel}
                        value={getAssignedTeamRankString(setManagerRank)}
                        disabled={true}
                        InputProps={rankProps}
                    />
                }
                {children}
            </Grid>
        </Grid>
    )
}
export default DeptEntry;