import React from 'react';
import {FormControlLabel, Checkbox, Tooltip} from "@mui/material";
import {isEmpty} from "fit/system/UtilityFunctions";
import {PRODUCTION_STATUS} from "fit/system/FITConstants";

const TeamEntry=({teamName, checked, toggleFn, disabled, color, interfaceSelf, invitePending, deptInvitePending, teamSectionID, teamOverridden, organizationDisabled, deptDisabled})=> {
    let tip;
    if (interfaceSelf && !teamOverridden) {
        tip = `Remove/Quit ${teamName}`
    } else if (invitePending) {
        tip = `Active invitation pending`;
    } else if(deptInvitePending){
       tip = 'Active department invitation pending';
    }  else if(checked && disabled && !organizationDisabled){
        tip = 'Aleady assigned'; //invites interface with active existing permissions
    } else if((!checked && disabled) || deptDisabled){
        tip = 'Disabled';
    } else if(teamOverridden) {
        tip = 'Overridden';
    } else if(checked && disabled && organizationDisabled){
            tip = 'Organization disabled'; //User doesn't have the scope/permission access to manage the organization or department
    } else {
        tip = `Toggle ${teamName}`
    }

    const activeFunction = teamOverridden ? ()=>console.warn('TEAM OVERRIDDEN: CANT EXECUTE') : ()=>toggleFn();
    const teamLabel = PRODUCTION_STATUS ? teamName : `${teamName}: [${teamSectionID}]`;
    return (
        <FormControlLabel
            label={teamLabel}
            control={
                <Tooltip title={tip} placement={'left'}>
                    <span>
                        <Checkbox
                            color={color}
                            checked={checked || invitePending}
                            onClick={activeFunction}
                            disabled={disabled}
                        />
                    </span>
                </Tooltip>
            }
        />
    )
}

export default TeamEntry;



//OLD FOR ORIGINAL PERMISSIONS
//UPDATE TO NEW

const _TeamEntry=({viewerPermissions, assignedPermissions, toggleFn, disabled, color})=>{
    const {orgShortName, label} = viewerPermissions;
    const teamName = `${orgShortName} ${label}`;
    const checked = !isEmpty(assignedPermissions); // && parseInt(assignedPermissions.active) === 1;
    //console.log('TEAM', assignedPermissions, checked);

    return (
        <FormControlLabel
            control={
                <Checkbox
                    color={color}
                    checked={checked && !disabled}
                    onClick={()=>toggleFn(viewerPermissions.sectionID, !checked)}
                    disabled={disabled}
                />
            }
            label={teamName}
        />
    )
}
