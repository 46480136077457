import React from 'react';
import {Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import {
    COMPANY_NAME,
    URL_DEVICES,
    URL_TERMS_OF_SERVICE,
    URL_PRIVACY_POLICY,
    URL_LOGIN,
    URL_REGISTER,
} from "fit/system/FITConstants";
import Link from 'fit/components/System/Link';
import {QrCode} from "@mui/icons-material";
import User from 'fit/system/User';

const Footer = () => {
    const startYear = 2024;
    const year = new Date().getFullYear();
    const yearString = year > startYear ? `${startYear}-${year}` : startYear;
    const user = new User();
    const loggedIn = user.getLoggedInStatus();
    return (
        <Div sx={{
            py: 2,
            px: {lg: 6, xs: 4},
            borderTop: 2,
            borderColor: 'divider',
            bgcolor: 'background.paper',
        }}>
            {
                !loggedIn && <small>
                    <Link to={URL_LOGIN}>Login</Link> | <Link to={URL_REGISTER}>Create Account</Link>
                </small>
            }

            <Div sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography
                    variant={"body1"}
                    color={"text.primary"}
                >
                <small>
                    Use of this platform constitutes agreement with our <Link to={URL_TERMS_OF_SERVICE}>Terms of Service</Link> and <Link to={URL_PRIVACY_POLICY}>Privacy Policy</Link>
                    <br/>Copyright  &copy; {yearString} {COMPANY_NAME.toUpperCase()} All Rights Reserved
                </small>

                </Typography>
                <Div sx={{display: 'flex', alignItems: 'center'}}>
                    {!loggedIn &&
                        <Link to={`${URL_DEVICES}login/`} aria-label={"Scan QR Code for Fast Device Logins"} title={'Device/Kiosk Login'}>
                            <QrCode/>
                        </Link>
                    }
                </Div>
            </Div>
        </Div>
    );
};

export default Footer;
