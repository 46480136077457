import User from "fit/system/User";
import {isEmpty, isArray} from "fit/system/UtilityFunctions";
import {SharedPermissions, RoutePermissions, LoggedOutPermissions, DevicePermissions, LegalRoutes} from "./PermissionsList";
import UserOnboarding from "../components/User/UserOnboarding";
import {APP_ACCESS_TYPES, PRODUCTION_STATUS, PERM_DEPT, ATHLETE_DEPT_ID, ATHLETE_LEVEL} from "./FITConstants";
/*
    -
    -
 */
//PermissionMenuTranslator Translates permissions into menu items

const determinePermitted=(accessRequirements, label)=>{
    const user = new User();
    const {type} = accessRequirements;
    const {athleteSection, permissions, appUpgrade, defaultAccess, device, development} = APP_ACCESS_TYPES;
    /*
        Concept: Determine whether a route is permitted based on different data structures:
            -Current Data Structures: AppAccess, Permissions
        Permissions Structure: TYPE: 'PERMISSIONS'
            -{deptID: #, managerLevel: #, strict t/f}
        AppAccess: TYPE: 'APP_ACCESS'
            -This is a work in progress:
    */

    switch(type){
        case defaultAccess:
            //Default access for the app. Always true
            return true;
        case development:
            //Allow access as long as prod status NOT true
            return PRODUCTION_STATUS === false;
        case permissions:
            const {deptID, strict} = accessRequirements;
            const requiredLevel = accessRequirements.managerLevel != null ? parseInt(accessRequirements.managerLevel) : 0;
            if(!isArray(deptID)) {
                //single department required.
                return user.managerSystemDeptLevel(deptID, requiredLevel, strict)
            }
            //Multiple departments listed. Run through each department and determine if the the test passed
            //Must be found within the list of departments
            const deptList = deptID;
            for(let k=0; k<deptList.length; k++){
                if(user.managerSystemDeptLevel(deptList[k], accessRequirements.managerLevel, strict)){
                    //department found within the list
                    return true;
                }
            }
            return false;
        case athleteSection:
            /*
            Similar to permissions:
            -These are the permissions checks for athletes
            -Must be assigned to athlete dept
            -has the rank of athlete OR
            -database teams attached (not overridden athlete teams)
            */
            const depts = user.getPermissions(PERM_DEPT);
            const athleteDeptIndex = depts.findIndex(d => parseInt(d.deptID) === ATHLETE_DEPT_ID && ((parseInt(d.managerLevel) === ATHLETE_LEVEL || d.dbTeams && isArray(d.dbTeams) && d.dbTeams.length > 0)));
            //Athlete Dept meeting manager/dbTeams requirement??
            return athleteDeptIndex > -1;
            break;
        case device:
            //NOT CURRENTLY ACTIVE. USING PERMISSIONS INSTEAD.
            //PLACEHOLDER?
            return false;

            break;
        case appUpgrade:
            //Get variables from AppAccess datastructure

            return false;
        default:
            //Outside of scope. Return nothing.
            return false;
    }
}


const initRoute =(route)=>{
    const user = new User();
    const {deptID, label, access} = route;
    const hasRoutes = !isEmpty(route.routes);
    const reqManagerLevel = route.managerLevel != null ? parseInt(route.managerLevel) : 0;
    const strictDept = route.strictLevel != null ? route.strictLevel : false;
    const userInDept = user.managerSystemDeptLevel(deptID, reqManagerLevel, strictDept);
    const permitted = determinePermitted(access, route.label);


    return {
        deptID,
        label,
        permitted: permitted,
        reqManagerLevel,
        strictDept,
        hasRoutes,
    }
}

export const PermissionMenuTranslator = (permissionsList) =>{
    //Translate the RouteLists into datastructure
    const user = new User();
    let menu = [];
    let menuSectionIDs = [];
    permissionsList.forEach((item, j)=>{
        //Determine if the user is active within the department
        let menuKey;
        const {deptID, label, permitted, hasRoutes} = initRoute(item);
        if(permitted && hasRoutes) {
            //User is active within dept and routes exist for the current dept
            item.routes.forEach((route, k) => {
                //Check whether the user is permitted to access each
                //const hasPermission = user.managerSystemDeptLevel(deptID, route.managerLevel, route.strictLevel);
                const hasPermission = determinePermitted(route.access, route.label);
                if(route.menuDisplay === true && hasPermission){
                    //Menu is permitted to be displayed.
                    //Create the menu entry if it hasn't been displayed already.
                    if(menuSectionIDs.includes(j) === false){
                        //Initialize Menu item Entry
                        menu.push({
                            label: label,
                            type: "section",
                            children: []
                        });
                        menuKey = menu.length-1;
                        //Add the entry to list of menu items
                        menuSectionIDs.push(j);
                    }
                    const routeIsFolder = route.subRoutes != null && !isEmpty(route.subRoutes);
                    const folderType = 'collapsible';
                    const linkType = 'nav-item'
                    const itemType = routeIsFolder ? folderType : linkType;

                    const navItemObject = {
                        uri: route.path,
                        label: route.label,
                        type: itemType,
                        icon: route.icon
                    }

                    const navFolderObject = {
                        label: route.label,
                        type: itemType,
                        icon: route.icon,
                        children: []
                    }

                    //Is a Collapsible folder with subroutes

                    //Create the entry for the child
                    const currentKey = menu[menuKey].children.length;
                    menu[menuKey].children[currentKey] = routeIsFolder ? navFolderObject : navItemObject;


                    if(routeIsFolder){
                        //Create Entries for subRoutes within folder//
                        let childrenItems = [];
                        route.subRoutes.forEach(sr =>{
                            //const permitted = user.managerSystemDeptLevel(deptID, sr.managerLevel);
                            const permitted = determinePermitted(sr.access, sr.label);
                            if(sr.menuDisplay === true && permitted) {
                                childrenItems.push({
                                    uri: sr.path,
                                    label: sr.label,
                                    type: linkType
                                });
                            }
                        });
                        //Assign children Items to the children array of the folder
                        menu[menuKey].children[currentKey].children = childrenItems;
                    }
                }
            });
        }
    });
    return menu;
};


const translateElement=(deviceAccount, routeElement)=>{
    //Forces the user to onboard or execute any mandatory tasks before proceeding
    const user = new User();
    const onboarded = user.getOnboarded();
    if(deviceAccount){
        return routeElement;
    }
    return onboarded ? routeElement : <UserOnboarding/>;
}

export const PermissionRouteTranslator = (authorized, deviceAccount) =>{
    let routes = [];
    let permissionsList;
    if(authorized){
        //Standard user or device permissions based on device account status
        permissionsList = !deviceAccount ? RoutePermissions : DevicePermissions;
    } else{
        //Just pass permissions for logged out users along with legalRoutes
        const routes = LoggedOutPermissions.concat(LegalRoutes);
        return routes;
    }
    //AUTHORIZED USERS
    permissionsList.forEach((item)=>{
        const {deptID, permitted, hasRoutes} = initRoute(item);
        if(permitted && hasRoutes){
            item.routes.forEach((route)=>{
                //const hasPermission = user.managerSystemDeptLevel(deptID, route.managerLevel, route.strictLevel);
                const hasPermission = determinePermitted(route.access, route.label);
                const hasSubRoutes = route.subRoutes != null && !isEmpty(route.subRoutes);
                if(hasPermission && route.path != null){
                    routes.push({path: route.path, element: translateElement(deviceAccount,route.element)});
                }
                if(hasSubRoutes){
                    route.subRoutes.forEach((sr)=>{
                        //const permitted = user.managerSystemDeptLevel(deptID, sr.managerLevel, sr.strictLevel);
                        const permitted = determinePermitted(route.access, route.label);
                        if(permitted) {
                            routes.push({path: sr.path, element: translateElement(deviceAccount, sr.element)});
                        }
                    });
                }
            });
        }
    });
    //Add Shared Permissions for Authorized Users that aren't device accounts
    if(authorized && deviceAccount === false){
        SharedPermissions.forEach((route)=>{
            const {deptIDs} = route;
            if(isArray(deptIDs)){
                for(let k=0; k<deptIDs.length; k++){
                    const dept = deptIDs[k];
                    //const permitted = user.managerSystemDeptLevel(dept.deptID, dept.managerLevel, dept.strictLevel);
                    const permitted = determinePermitted(route.access, route.label);
                    if(permitted){
                        routes.push({path: route.path, element: translateElement(false, route.element)});
                        //Terminate loop. User Is Permitted
                        break;
                    }
                }
            } else{
                //Automatically include
                routes.push({path: route.path, element: translateElement(false, route.element)});
            }
        })
    }
    console.log('-------------------------------');

    //Add legal routes
    routes = routes.concat(LegalRoutes);
    return routes;
}