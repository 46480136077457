import React from 'react';
import {Typography} from "@mui/material";

const TermsOfService=()=>{
    return (
        <div>
        Coming Soon...
        </div>
    )
}

export default TermsOfService;

