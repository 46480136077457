import React from 'react';
import {Button} from '@mui/material';
import {Close} from "@mui/icons-material";

const CloseButton=({onClick, disabled = false, fullWidth=false, size='medium', sx, text})=>{
    const buttonText = text != null ? text : 'Close';
    return (
        <Button
            fullWidth={fullWidth}
            size={size}
            sx={sx}
            color={'secondary'}
            variant={'contained'}
            disabled={disabled}
            onClick={onClick}
        ><Close/>{buttonText}</Button>
    )
}

export default CloseButton;