import React from 'react';
import Header from "../Header";
import PaperMargin from "../PaperMargin";
const LegalPage=({header, children})=>{
    return (
        <div>
            <Header header={header}/>
            <PaperMargin>
                {children}
            </PaperMargin>
        </div>
    )

}

export default LegalPage;